export const checkMobile = (str) => {
    let reg = /^1\d{10}$/;
    return constantValidate(reg,str);
}

export const checkEmail = (str) => {
    let reg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    return constantValidate(reg,str);
}

export const checkPassWord = (str) => {
    let reg = /^[0-9a-zA-Z_@#$%^.&]{6,16}$/;
    return constantValidate(reg,str);
}

export const checkCaptcha = (str) => {
    let reg = /^[0-9]*[1-9][0-9]*$/;
    return constantValidate(reg,str);
}

export function debounce(func, wait) {
    let timer;
    return function () {
        let context = this; // 这边的 this 指向谁?
        let args = arguments; // arguments中存着e
        if (timer) clearTimeout(timer);
        let callNow = !timer;
        timer = setTimeout(() => {
            timer = null;
        }, wait)
        if (callNow) func.apply(context, args);
    }
}

 function constantValidate(reg,str) {
    if(reg.test(str)) {
        return true;
    }else {
        return false;
    }
 }