import Vue from 'vue'
import App from './App.vue'
import router from './router'
import element from './utils/element'
import './assets/iconfont/iconfont.css'
import './assets/iconfont/iconfont.js'
import 'normalize.css/normalize.css'
import '@/styles/index.less'
import 'element-ui/lib/theme-chalk/index.css';
import './permission'
import width from './utils/width';

Vue.use(element);
Vue.mixin(width);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
