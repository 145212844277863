import request from  '@/utils/request'

export function register(data) {
    return request({
        url: '/waterfall/user/register',
        method: "post",
        data
    })
}

export function registerCaptcha(data) {
    return request({
        url: '/waterfall/user/register-captcha',
        method: "post",
        data
    })
}

export function login(data) {
    return request({
        url: '/waterfall/user/login',
        method: "post",
        data
    })
}

export function loginCaptcha(data) {
    return request({
        url: '/waterfall/user/login-captcha',
        method: "post",
        data
    })
}

export function resetPassWord(data) {
    return request({
        url: "/waterfall/user/reset-password",
        method: "put",
        data
    })
}

export function existUserName(data) {
    return request({
        url: "/waterfall/user/exist-username",
        method: "post",
        data
    })
}

export function resetCaptcha(data) {
    return request({
        url: "/waterfall/user/reset-captcha",
        method: "post",
        data
    })
}

export function userInfo() {
    return request({
        url: '/waterfall/user/info',
        method: "get",
    })
}

export function editUserInfo(data) {
    return request({
        url: "/waterfall/user/edit",
        method: "put",
        data
    })
}

export function userStat() {
    return request({
        url: "/waterfall/user/stat",
        method: "get"
    })
}

export function logout() {
    return request({
        url: "/waterfall/user/logout",
        method: "post"
    })
}