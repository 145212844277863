<template>
  <div class="word-item" :class="active === index ? 'word-item-active' : ''">
    <div class="item-left">
      <div class="item-top">
        <span class="item-name">{{ content.spelling }}</span>
        <span class="item-newWords" v-if="content.wordFamiliarityType === 0"
          >新词</span
        >
        <span class="item-recite" v-else-if="content.wordFamiliarityType === 2"
          >熟词</span
        >
        <span
          class="item-intimacy"
          v-else-if="content.wordFamiliarityType === 1"
          >背诵中</span
        >
      </div>
      <div class="item-bs">
        <span v-for="(item, index) in content.reads" :key="index">
          <span class="mr">{{ item.abbr }}</span>
          <span class="mr">{{ item.phonetic }}</span>
          <span
            class="item-player mr"
            @click="onPlay('readBottomAudio', index)"
          >
            <audio
              :src="item.pronunciation"
              hidden
              controls="controls"
              :ref="'readBottomAudio' + index"
            ></audio>
            <i class="iconfont icon-yinpin"></i>
          </span>
        </span>
      </div>
      <div class="item-message" @click="onShowColl(index)">
        <div
          v-for="(item, idx) in content.properties"
          :key="idx"
          style="margin: 0"
        >
          <span>{{ item.abbr }}</span>
          <span>{{ item.subAbbr }}</span>
          <div class="over">
            <span v-for="(ele, j) in item.forms" :key="j">
              <span>{{ ele.formCn }}:</span>
              <span>{{ ele.spelling }}</span>
              <span>{{ ele.phonetic }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="item-right">
      <div class="item-progress">
        <span class="item-msg">
          <span>熟悉度</span>
          <img src="../assets/jiantou.png" alt="" />
        </span>
        <el-progress
          type="circle"
          :percentage="content.familiarity"
          :width="54"
          color="#ED7632"
        ></el-progress>
      </div>
      <div class="item-btns">
        <div
          class="item-join"
          v-if="content.wordFamiliarityType === 0"
          @click="handleJoin"
        >
          <i class="iconfont icon-book-2"></i>
          加入
        </div>
        <div
          class="item-already-join"
          v-if="content.wordFamiliarityType === 2 && $route.path === '/book'"
          @click="handleSet('unfamiliar')"
        >
          取消熟词
        </div>
        <div
          class="item-join"
          v-if="content.wordFamiliarityType === 1 && $route.path === '/book'"
          @click="handleSet('familiar')"
        >
          <i class="iconfont icon-a-Property1shuci"></i>
          设为熟词
        </div>
        <div
          class="item-already-join"
          v-if="content.wordFamiliarityType !== 0 && $route.path === '/word'"
        >
          <i class="iconfont icon-book-2"></i>
          已添加
        </div>
      </div>
    </div>
    <div class="item-collapse scroll-area" v-if="active === index">
      <div
        class="collapse-text"
        v-for="(item, idx) in content.properties"
        :key="idx"
      >
        <p>
          <span>{{ item.abbr }}</span>
          <span>{{ item.subAbbr }}</span>
        </p>
        <p>
          <span v-for="(ele, j) in item.forms" :key="j">
            <span>{{ ele.formCn }}:</span>
            <span>{{ ele.spelling }}</span>
            <span>{{ ele.phonetic }}</span>
            <span
              @click="onPlay('readsCollAudio' + item.propertyEn, j)"
              class="coll-palyer"
              v-if="ele.pronunciation"
            >
              <audio
                :src="ele.pronunciation"
                hidden
                controls="controls"
                :ref="'readsCollAudio' + item.propertyEn + j"
              ></audio>
              <i class="iconfont icon-yinpin"></i>
            </span>
          </span>
        </p>
        <p v-for="(txt, x) in item.definitions" :key="x">
          <span>{{ x + 1 }}.</span>
          <span>{{ txt.translationCn }}</span>
          <span>{{ txt.translationEn }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: {},
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      active: -1,
    };
  },
  created() {
    if (this.index === 1) {
      console.log(this.content);
    }
  },
  methods: {
    handleSet(val) {
      this.$emit("set", { id: this.content.wordId, type: val });
    },
    handleJoin() {
      this.$emit("join", this.content.wordId);
    },
    onPlay(tit, idx) {
      let refName = tit + "" + idx;
      this.$refs[refName][0].play();
    },
    onShowColl(idx) {
      if (this.active === idx) {
        this.active = -1;
      } else {
        this.active = idx;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.scroll-area::-webkit-scrollbar {
  width: 10px;
  height: 2px;
}

/* --- 滚动条里面的滚动块 --- */
.scroll-area::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c1cfcc;
}

/deep/ .el-progress__text {
  color:#792607;
}
@media (min-width: 768px) and (max-width: 2640px) {
  .flx {
    display: flex;
  }
  .mr {
    margin-right: 5px;
  }
  .word-item {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 580px;
    border-radius: 20px;
    background: #f7f6f4;
    padding: 20px;
    box-sizing: border-box;
    height: 160px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #252525;
    .item-collapse {
      position: absolute;
      top: 176px;
      left: 0;
      width: 100%;
      height: 236px;
      overflow: auto;
      border-radius: 20px;
      background: #eaf0ef;
      border: 1px solid #2c908c;
      box-shadow: 0px -8px 8px 0px #031b1d14;
      z-index: 99;
      padding: 20px;
      box-sizing: border-box;
      .collapse-text {
        color: #424242;
        font-size: 16px;
        line-height: 28px;
        text-align: left;
        margin: 0;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          margin: 0;
        }
        .coll-palyer {
          color: #ed7632;
          margin: 0 12px;
          cursor: pointer;
        }
        span {
          margin-right: 4px;
        }
      }
    }
    &:nth-of-type(even) {
      margin-right: 0;
    }
    .item-left {
      .item-player {
        width: 18px;
        height: 18px;
        color: #ed7632;
        margin-right: 12px;
        cursor: pointer;
      }
      .item-top {
        display: flex;
        align-items: center;

        margin-bottom: 10px;
        .item-name {
          font-size: 20px;
          color: #252525;
          margin-right: 12px;
        }

        .item-newWords,
        .item-intimacy,
        .item-recite {
          width: 48px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 30px;
          color: #ffffff;
        }
        .item-newWords {
          background: #d06456;
        }
        .item-intimacy {
          background: #3382ca;
        }
        .item-recite {
          background: #bca371;
        }
      }
      .item-bs {
        margin-bottom: 14px;
        color: #595959;
        font-size: 14px;
      }
      .item-message {
        width: 360px;
        height: 56px;
        color: #424242;
        font-size: 16px;
        line-height: 28px;
        overflow: hidden;
        .over {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .item-right {
      display: flex;
      flex-direction: column;

      .item-progress {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        .item-msg {
          font-size: 14px;
          margin-right: 16px;
        }
        img {
          display: block;
          width: 50px;
          height: 3px;
          margin-top: 2px;
        }
      }
      .item-btns {
        display: flex;
        justify-content: flex-end;
        .item-join,
        .item-already-join {
          border-radius: 30px;
          height: 36px;
          font-size: 16px;
          text-align: center;
          line-height: 36px;
        }
        .item-join {
          padding: 0 15px;
          box-sizing: border-box;
          background: #2c908c;
          color: #ffffff;
          cursor: pointer;
        }
        .item-already-join {
          width: 88px;
          background: #eaf0ef;
          color: #2c908c;
          cursor: pointer;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .flx {
    display: flex;
  }
  .mr {
    margin-right: 5px;
  }
  .word-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    border-radius: 10px;
    background: #f7f6f4;
    padding: 10px;
    box-sizing: border-box;
    height: 160px;
    margin-bottom: 17px;
    font-size: 12px;
    color: #252525;
    .item-collapse {
      position: absolute;
      top: 164px;
      left: 0;
      width: 100%;
      height: 250px;
      overflow: auto;
      border-radius: 10px;
      background: #eaf0ef;
      border: 1px solid #2c908c;
      z-index: 99;
      padding: 10px;
      box-sizing: border-box;
      .collapse-text {
        color: #424242;
        font-size: 14px;
        line-height: 28px;
        text-align: left;
        margin: 0;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          margin: 0;
        }
        .coll-palyer {
          color: #ed7632;
          margin: 0 12px;
          cursor: pointer;
        }
        span {
          margin-right: 4px;
        }
      }
    }
    .item-left {
      .item-player {
        width: 18px;
        height: 18px;
        color: #ed7632;
        margin-right: 12px;
      }
      .item-top {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .item-name {
          font-size: 20px;
          color: #252525;
          margin-right: 12px;
        }
        .item-newWords,
        .item-intimacy,
        .item-recite {
          width: 48px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 30px;
          color: #ffffff;
        }
        .item-newWords {
          background: #d06456;
        }
        .item-intimacy {
          background: #3382ca;
        }
        .item-recite {
          background: #bca371;
        }
      }
      .item-bs {
        margin-bottom: 14px;
        color: #595959;
        font-size: 14px;
      }
      .item-message {
        width: 190px;
        height: 56px;
        color: #424242;
        font-size: 16px;
        line-height: 28px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
    .item-right {
      display: flex;
      flex-direction: column;

      .item-progress {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 20px;
        .item-msg {
          font-size: 12px;
          margin-top: 6px;
          text-align: center;
        }
        img {
          display: block;
          width: 50px;
          height: 3px;
          margin-top: 2px;
        }
      }
      .item-btns {
        display: flex;
        justify-content: flex-end;
        .item-join,
        .item-already-join {
          border-radius: 30px;
          height: 36px;
          font-size: 16px;
          text-align: center;
          line-height: 36px;
        }
        .item-join {
          padding: 0 15px;
          background: #2c908c;
          color: #ffffff;
        }
        .item-already-join {
          width: 88px;
          background: #eaf0ef;
          color: #2c908c;
        }
      }
    }
  }
}
</style>