<template>
  <div class="register-form">
    <el-form :model="formModel" :rules="formRules" ref="formRef">
      <el-form-item label="邮箱/国内手机" prop="username">
        <el-input v-model="formModel.username" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item :label="labelTxt" prop="password" v-if="!needPass">
        <el-input
          v-model="formModel.password"
          type="password"
          placeholder="请输入"
          show-password
        >
        </el-input>
        <p
          class="passWord-msg"
          v-if="currTpl == 'register' || currTpl == 'forget'"
        >
          <!-- <span>！</span> -->
          密码为6~16位数字、字母、符号
        </p>
        <p class="register-explain" v-if="currTpl === 'login'">
          <span @click="$emit('forget', 'forget')">忘记密码</span>
        </p>
      </el-form-item>
      <el-form-item label="验证码" prop="captcha" v-if="!needVer">
        <el-input
          v-model="formModel.captcha"
          placeholder="请输入"
          class="pr"
          maxlength="6"
        >
        </el-input>
        <div class="ver-btn" @click="handleVerificationCode">
          {{ vfcTxt }}
        </div>
      </el-form-item>
      <el-form-item>
        <button @click.prevent="handleEvents" class="register-btn">
          {{ showBtn }}
        </button>
        <p
          class="register-explain"
          v-if="currTpl === 'register' || currTpl === 'login'"
        >
          {{ showExplain }} <span @click="handleGo">{{ showGoText }}</span>
        </p>
        <p
          class="register-explain"
          v-if="currTpl === 'forget'"
          @click="$emit('forget', 'login')"
        >
          返回上一步
        </p>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { registerCaptcha, loginCaptcha, resetCaptcha } from "@/api/user";
export default {
  props: {
    formModel: {
      type: Object,
      required: true,
    },
    formRules: {
      type: Object,
      default: {},
    },
    needPass: {
      type: Boolean,
      default: false,
    },
    needVer: {
      type: Boolean,
      default: false,
    },
    currTpl: {
      type: String,
      default: "start",
    },
  },
  data() {
    return {
      vfcTime: 0,
      vfcTimer: null,
      vfcTxt: "获取验证码",
    };
  },
  computed: {
    labelTxt() {
      return this.currTpl === "register"
        ? "设置密码"
        : this.currTpl === "forget"
        ? "新密码"
        : this.currTpl === "login"
        ? "密码"
        : "";
    },
    showBtn() {
      if (this.currTpl === "forget") {
        return "确认";
      } else {
        if (!this.needPass && !this.needVer) {
          return "注册";
        } else {
          return "登录";
        }
      }
    },
    showExplain() {
      if (!this.needPass && !this.needVer) {
        return "已有账号";
      } else {
        return "没有账号，点击立即";
      }
    },
    showGoText() {
      if (!this.needPass && !this.needVer) {
        return "去登录";
      } else {
        return "免费注册";
      }
    },
  },
  methods: {
    handleGo() {
      let goVal = "";
      if (!this.needPass && !this.needVer) {
        goVal = "login";
      } else {
        goVal = "register";
      }
      this.$emit("go", goVal);
    },
    handleEvents() {
      let mess = "";
      if (!this.needPass && !this.needVer) {
        mess = "register";
      } else if (this.needPass === false && this.needVer === true) {
        mess = "pass";
      } else if (this.needPass === true && this.needVer === false) {
        mess = "ver";
      }
      this.$refs.formRef.validate((res) => {
        if (res) {
          this.$emit("submit", mess);
        }
      });
    },
    handleVerificationCode() {
      this.$refs.formRef.validateField("username", (res) => {
        if (res) return;
        if (this.vfcTimer) {
          return;
        }
        let req = {
          username: this.formModel.username,
        };
        if (this.currTpl === "register") {
          registerCaptcha(req).then((res) => {
            console.log(res);
            this.vfcTime = res.data.cooldown;
            this.$message.success("验证码已发送，请注意查收。");
          });
        } else if (this.currTpl === "login") {
          loginCaptcha(req).then((res) => {
            this.vfcTime = res.data.cooldown;
            this.$message.success("验证码已发送，请注意查收。");
          });
        } else if (this.currTpl === "forget") {
          resetCaptcha(req).then((res) => {
            this.vfcTime = res.data.cooldown;
            this.$message.success("验证码已发送，请注意查收。");
          });
        }

        this.vfcTimer = setInterval(() => {
          this.vfcTime--;
          if (this.vfcTime < 1) {
            this.vfcTxt = `重新发送`;
            clearInterval(this.vfcTimer);
            this.vfcTimer = null;
            this.vfcTime = 0;
          } else {
            this.vfcTxt = `已发送（${this.vfcTime}）`;
          }
        }, 1000);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.register-form {
  /deep/.el-input__inner:focus {
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
  }
}
/deep/ .el-form-item__content {
  position: relative;
  .passWord-msg {
    color: #8c8c8c;
    position: absolute;
    right: 0;
    bottom: -23px;
    margin: 0;
    font-size: 12px;
    line-height: 12px;
  }
  .ver-btn {
    position: absolute;
    right: 0;
    bottom: 10px;
    padding: 7px 15px;
    border-radius: 20px;
    border: 1px solid #2c908c;
    color: #2c908c;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
  }
}
/deep/ .el-input__inner {
  padding: 0;
  border-radius: 0;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}
/deep/ .el-input__inner:focus {
  border-bottom-color: #2c908c;
}
/deep/ .el-input-group__append {
  position: relative;
  background: transparent;
  border-right: transparent;
  border-top: transparent;
}
/deep/ .el-input__inner:hover {
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
}
@media (min-width: 768px) and (max-width: 2640px) {
  .register-btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 30px;
    background: #2c908c;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 38px;
  }
  .register-explain {
    margin: 0;
    margin-top: 12px;
    color: #8c8c8c;
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    span {
      color: #2c908c;
    }
  }
}
@media (max-width: 768px) {
  .register-btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 30px;
    background: #2c908c;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    margin-top: 38px;
  }
  .register-explain {
    margin: 0;
    margin-top: 12px;
    color: #8c8c8c;
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    span {
      color: #2c908c;
    }
  }
}
</style>