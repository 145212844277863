import axios from 'axios'
import { getToken, removeToken } from './auth'
import {Message} from 'element-ui';
import router from '../router'
const ERR_CODE = "00"
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
    // baseURL: "http://localhost:8080",
    baseURL: "https://wordfall.net",
})

service.interceptors.request.use(
    config => {
        if(getToken()) {
            config.headers['Authorization'] = getToken();
        }  
        return config
    },
    error => {
        return Promise.reject(error)
    },
)
service.interceptors.response.use(
    response => {
        const result = response.data;
        if(result.errCode == 401) {
            removeToken();
            router.push('/login');
            return result;
        }
        if(result.errCode !== ERR_CODE) {
            Message({
                message: result.errMsg || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(new Error(result.message || 'Error'));
        }else {
            return result;
        }
    },
    error => {
        return Promise.reject(error)
    }
)
export default service;