const TokenKey = "WordFall-Token"
export const getToken = () => {
    return localStorage.getItem(TokenKey);
}

export const setToken = (tk) => {
    return localStorage.setItem(TokenKey,tk)
}

export const removeToken = () => {
    return localStorage.removeItem(TokenKey)
}

export const setIsFinish = () => {
    return sessionStorage.setItem("IsFinish",1);
}

export const getIsFinish = () => {
    return sessionStorage.getItem("IsFinish");
}

export const removeIsFinish = () => {
    return sessionStorage.removeItem("IsFinish");
}
export const MAXLEN = 5;