<script>
export default {
  name: "Title",
  functional: true,
  props: {
    topicType: {
      require: true,
      type: [String, Number],
    },
  },
  render(h, context) {
    const { topicType } = context.props;
    let txt = "";
    const vnodes = [];
    switch (topicType) {
      case 1:
        txt = "【题型1】选出英文词汇的：词性和中文释义";
        break;
      case 2:
        txt = "【题型2】选出词性和英文释义的：英文词汇";
        break;
      case 3:
        txt = "【题型3】选出词性和中文释义的：英文词汇";
        break;
      case 4:
        txt = "【题型4】选出例句中对应的英文单词";
        break;
      case 5:
        txt = "【题型5】填出英文释义对应的英文单词";
        break;
      default:
          txt = "这个是啥题型呢？？？？？？"
        break;
    }
    vnodes.push(<p>{txt}</p>)
    return vnodes
  },
};
</script>