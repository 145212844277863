export default {
    data() {
        return {
            clientWidth: window.innerWidth,
            netWork: true
        }
    },
    methods: {
        getClientWidth() {
            if(this.$children && this.$children.length > 0) {
                this.$children.forEach(item => {
                    item.clientWidth = window.innerWidth;
                    item.getClientWidth();
                })
            }
        },
        getNetWork() {
            if(this.$children && this.$children.length > 0) {
                this.$children.forEach(item => {
                    item.netWork = !item.netWork;
                    item.getNetWork();
                })
            }
        }
    }
}