<template>
  <div class="list-item" :style="needWidth ? widthObj : ''">
    <div class="item-photo">
      <img v-if="content.cover" :src="content.cover" alt="" />
    </div>
    <div class="item-info">
      <div class="title-h-text item-name">{{ content.spelling }}</div>
      <div class="title-p-text item-test">
        <p class="item-pro">练习进度：{{ pro }}</p>
        <div v-for="(ele, j) in content.states" :key="j" class="item-status">
          <img
            src="../assets/weiwancheng.svg"
            v-if="ele === 0"
            alt=""
            class="weiwancheng"
          />
          <img
            src="../assets/wancheng.svg"
            v-if="ele === 1"
            alt=""
            class="weiwancheng"
          />
          <img
            src="../assets/cuowu.svg"
            v-if="ele === 2"
            alt=""
            class="weiwancheng"
          />
        </div>
      </div>
    </div>
    <div class="item-progress">
      <span class="item-msg">
        <span>熟悉度</span>
        <img src="../assets/jiantou.png" alt="" />
      </span>
      <el-progress
        type="circle"
        :percentage="content.familiarity"
        :width="54"
        color="#ED7632"
      ></el-progress>
    </div>
    <div v-if="needToggle" class="item-toggle" @click="handleToggle">
      <i class="icon-a-Property1repeat iconfont"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: {},
    },
    index: Number,
    needWidth: {
      type: Boolean,
      default: false,
    },
    needToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      widthObj: {
        width: "560px",
        marginRight: "20px",
      },
    };
  },
  computed: {
    pro() {
      let maps = this.content.states.map((item) => item === 1);
      let sum = maps.reduce((prec, next) => prec + next);
      return `${sum}/5`;
    },
  },
  methods: {
    handleToggle() {
      this.$emit("toggle", this.content, this.index);
    },
  },
};
</script>

<style lang="less" scoped>
.blone {
  color: #e9dbc8;
}
.green {
  color: #2c908c;
}
.red {
  color: #d06456;
}
/deep/ .el-progress__text {
  color: #792607;
}
@media (min-width: 768px) and (max-width: 2640px) {
  .weiwancheng {
    width: 20px;
    height: 20px;
  }
  .list-item {
    width: 560px;
    margin-right: 20px;
    padding: 10px 11px 10px 110px;
    height: 100px;
    border-radius: 20px;
    background: #f7f6f4;
    margin-bottom: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .item-photo {
      width: 100px;
      height: 100px;
      border-radius: 20px;
      background: #e9dbc8;
      position: absolute;
      left: 10px;
      top: -10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }
    .item-info {
      margin-left: 20px;
      .item-pro {
        margin: 0;
        margin-right: 12px;
      }
      .item-name {
        margin-bottom: 9px;
      }
      .item-test {
        display: flex;
        align-items: center;
        .item-status {
          display: inline-block;
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .item-progress {
      display: flex;
      align-items: center;
      font-size: 14px;
      .item-msg {
        margin-right: 16px;
      }
      img {
        display: block;
        width: 50px;
        height: 3px;
        margin-top: 2px;
      }
    }
  }
}
@media (max-width: 768px) {
  .weiwancheng {
    width: 20px;
    height: 20px;
  }
  .list-item {
    width: 100%;
    padding: 10px 24px 10px 100px;
    height: 100px;
    border-radius: 20px;
    background: #f7f6f4;
    margin-bottom: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .item-photo {
      width: 80px;
      height: 80px;
      border-radius: 16px;
      background: #e9dbc8;
      position: absolute;
      left: 10px;
      top: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
      }
    }
    .item-info {
      .item-pro {
        margin: 0;
        margin-bottom: 6px;
      }
      .item-name {
        margin-bottom: 9px;
      }
      .item-test {
        .item-status {
          display: inline-block;
          margin-right: 8px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .item-progress {
      display: flex;
      flex-direction: column-reverse;
      .item-msg {
        text-align: center;
        font-size: 12px;
        margin-top: 4px;
      }
      img {
        display: block;
        width: 50px;
        height: 3px;
        margin-top: 2px;
      }
    }
  }
}
</style>
