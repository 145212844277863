<template>
  <div class="book-wrapper">
    <div class="book-top">
      <div class="book-header">
        <div class="goback" @click="$router.go(-1)">
          <i class="iconfont icon-back"></i>
        </div>
        <div class="book-tabs">
          <div :class="bookType === 1 ? 'active' : ''" @click="onBookTab(1)">
            <span>生词本</span>
          </div>
          <div :class="bookType === 2 ? 'active' : ''" @click="onBookTab(2)">
            <span>熟词本</span>
          </div>
        </div>
      </div>
    </div>
    <div class="book-content">
      <div class="book-main">
        <div class="book-setting">
          <div class="setting-left">
            <div class="setting-name need-xl-hidden">熟悉度:</div>
            <div class="setting-sort">
              <div
                class="sort-up"
                :class="currSort === 1 ? 'sort-active' : ''"
                @click="onSortTab(1)"
              >
                <i class="iconfont icon-a-Property1jiangxu-xuanzhong"></i>
                升序
              </div>
              <div
                class="sort-down"
                :class="currSort === 0 ? 'sort-active' : ''"
                @click="onSortTab(0)"
              >
                <i class="iconfont icon-a-Property1shengxu-xuanzhong"></i>
                降序
              </div>
            </div>
            <div class="setting-msg need-xl-hidden">
              <span>*</span>
              <p v-if="bookType == 1">
                已选生词不可以删除哦。你可把不需要背诵的词汇转换为永久熟词。
              </p>
              <p v-if="bookType == 2">你可取消永久熟词，然后继续背诵。</p>
            </div>
          </div>
          <div class="setting-right">
            <div class="go-home need-xl-hidden" @click="$router.push('/home')">
              <i class="iconfont icon-a-shouye_home2"></i>
            </div>
            <div
              class="go-recite"
              :class="bookList.length ? '' : 'disabled'"
              @click="onLink"
            >
              去背词
            </div>
          </div>
        </div>
        <div class="book-list" v-if="bookList.length">
          <word
            v-for="(item, index) in bookList"
            :key="index"
            :content="item"
            @set="onSet"
          ></word>
        </div>
        <div class="book-pagination need-xl-hidden" v-if="bookList.length">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currPage"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="word-pagination pagination need-lg-hidden">
          <div>
            <span>前往</span>
            <el-input
                    type="text"
                    v-model="currPage"
                    onkeyup="value=value.replace(/[^\d]/g,'')"
                    class="pagination-input"
                    @change="onGoPage"
            ></el-input>
            <span>页</span>
          </div>
          <div class="change-page" @click="onPrev">上一页</div>
          <div class="change-page" @click="onNext">下一页</div>
        </div>
        <el-empty
          v-if="!bookList.length"
          description="哎呀，生词本空空如也！点击下方立即查询添加生词吧！"
        >
          <div class="go-search" @click="$router.push('/word')">
            立即查询
            <i class="icon iconfont icon-jiantou"></i>
          </div>
        </el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import {
  recitationBook,
  recitationFamiliar,
  recitationUnFamiliar,
} from "@/api/recitation";
import word from "@/components/word";
export default {
  data() {
    return {
      bookType: 1,
      currSort: 1,
      bookList: [],
      currPage: 1,
      pageSize: 8,
      total: 0,
    };
  },
  computed: {
    hasWords() {
      return this.bookList.some(item => item.wordFamiliarityType === 1 )
    },
    myTotal() {
      return Math.ceil(this.total / this.pageSize);
    }
  },
  methods: {
    onGoPage(val) {
      if (!+val) return;
      if (+val > this.myTotal) {
        this.currPage = this.myTotal;
        this.getrRecitationBook();
        return;
      }
      this.currPage = +val;
      this.getrRecitationBook();
    },
    onPrev() {
      if (this.currPage === 1) {
        return;
      }
      this.currPage--;
      this.getrRecitationBook();
    },
    onNext() {
      if (this.currPage === this.myTotal) {
        return;
      }
      this.currPage++;
      this.getrRecitationBook();
    },
    onSet(obj) {
      let req = {
        wordId: obj.id,
      };
      if (obj.type === "familiar") {
        recitationFamiliar(req).then((res) => {
          this.$message.success(res.errMsg);
          this.getrRecitationBook();
        });
      } else if (obj.type === "unfamiliar") {
        recitationUnFamiliar(req).then((res) => {
          this.$message.success(res.errMsg);
          this.getrRecitationBook();
        });
      }
    },
    onLink() {
      if (!this.bookList.length && this.hasWords) return;
      this.$router.push("/recite");
    },
    onBookTab(val) {
      if (this.bookType === val) return;
      this.bookType = val;
      this.currPage = 1;
      this.getrRecitationBook();
    },
    onSortTab(val) {
      if (this.currSort === val) return;
      this.currSort = val;
      this.getrRecitationBook();
    },
    handleCurrentChange(val) {
      this.currPage = val;
      this.getrRecitationBook();
    },
    getrRecitationBook() {
      let req = {
        bookType: this.bookType,
        isAsc: this.currSort,
        pageNum: this.currPage,
      };
      recitationBook(req).then((res) => {
        this.bookList = res.data.list;
        this.total = res.data.total;
        console.log(res);
      });
    },
  },
  created() {
    this.getrRecitationBook();
  },
  components: {
    word,
  },
};
</script>

<style lang="less" scoped>
.book-wrapper {
  width: 100%;
  background: #fafafa;
}
.disabled {
  background: #bbd1d0;
  cursor: not-allowed;
}
.pagination {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  /deep/ .el-input__inner,
  .change-page {
    width: 80px;
    border-radius: 30px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border: 1px solid #f0f0f0;
  }
  /deep/ .el-input {
    width: 80px !important;
  }
  .pagination-input {
    margin: 0 10px;
  }
}
@media (min-width: 768px) and (max-width: 2640px) {
  .book-top {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .book-header {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 16px;
      span {
        cursor: pointer;
      }
      .book-tabs {
        display: flex;
        color: #595959;
        font-size: 16px;
        div:first-child {
          margin: 0 40px;
        }
        .active {
          color: #2c908c;
          font-weight: bold;
          font-size: 18px;
          position: relative;
        }
        .active::after {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 10px;
          background: #2c908c;
          width: 28px;
          height: 2px;
        }
      }
      .goback {
        width: 22px;
        height: 22px;
        color: #8c8c8c;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
  .book-content {
    background: #fafafa;
    display: flex;
    justify-content: center;
    .book-main {
      width: 1200px;
      padding: 0 10px;
      box-sizing: border-box;
      background: #ffffff;

      .book-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .book-pagination {
        display: flex;
        justify-content: center;
      }
      .go-search {
        width: 180px;
        height: 40px;
        border-radius: 71px;
        background: #2c908c;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
      }
      .book-setting {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
      }
      .setting-left {
        display: flex;
        align-items: center;

        color: #252525;
        font-size: 16px;
        .setting-sort {
          display: flex;
          color: #424242;
          font-size: 14px;
          margin-right: 32px;
          margin-left: 5px;
          .sort-up,
          .sort-down {
            width: 72px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #bbc5c3;
            cursor: pointer;
          }
          .sort-down {
            border-left: none;
            border-radius: 0px 12px 12px 0px;
          }
          .sort-up {
            border-right: none;
            border-radius: 12px 0px 0px 12px;
          }
          .sort-active {
            color: #2c908c;
            border: 1px solid #2c908c;
          }
        }
        .setting-msg {
          display: flex;
          color: #595959;
          font-size: 14px;
          span {
            color: #d06457;
          }
          p {
            margin: 0;
          }
        }
      }
      .setting-right {
        display: flex;
        .go-home {
          border-radius: 30px;
          background: #eaf0ef;
          width: 60px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          color: #2c908c;
          margin-right: 20px;
          cursor: pointer;
        }
        .go-recite {
          border-radius: 30px;
          background: #2c908c;
          width: 120px;
          height: 36px;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
        }
        .recite-disable {
          background: #bbd1d0;
          color: #ffffff;
          cursor: not-allowed;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .need-xl-hidden {
    display: none !important;
  }
  .book-top {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 14px;
    .book-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      font-size: 16px;
      position: relative;
      .book-tabs {
        display: flex;
        color: #595959;
        font-size: 16px;
        div {
          padding: 0 14px;
        }
        .active {
          color: #2c908c;
          font-weight: bold;
          font-size: 18px;
          position: relative;
        }
        .active::after {
          content: "";
          position: absolute;
          bottom: -6px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 10px;
          background: #2c908c;
          width: 28px;
          height: 2px;
        }
      }
      .goback {
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        color: #8c8c8c;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
  .book-content {
    background: #fafafa;
    display: flex;
    justify-content: center;
    .book-main {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      .book-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .book-pagination {
        display: flex;
        justify-content: center;
      }
      .go-search {
        width: 180px;
        height: 40px;
        border-radius: 71px;
        background: #2c908c;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
      }
      .book-setting {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
      }
      .setting-left {
        display: flex;
        align-items: center;
        color: #252525;
        font-size: 16px;
        .setting-sort {
          display: flex;
          color: #424242;
          font-size: 14px;
          margin-right: 32px;
          margin-left: 5px;
          .sort-up,
          .sort-down {
            width: 72px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #bbc5c3;
            cursor: pointer;
          }
          .sort-down {
            border-left: none;
            border-radius: 0px 12px 12px 0px;
          }
          .sort-up {
            border-right: none;
            border-radius: 12px 0px 0px 12px;
          }
          .sort-active {
            color: #2c908c;
            border: 1px solid #2c908c;
          }
        }
      }
      .setting-right {
        display: flex;
        .go-home {
          border-radius: 30px;
          background: #eaf0ef;
          width: 60px;
          height: 36px;
          margin-right: 20px;
        }
        .go-recite {
          border-radius: 30px;
          background: #2c908c;
          width: 120px;
          height: 36px;
          color: #ffffff;
          font-size: 14px;
          text-align: center;
          line-height: 36px;
        }
        .recite-disable {
          background: #bbd1d0;
          color: #ffffff;
          cursor: not-allowed;
        }
      }
    }
  }
}
</style>