<template>
 <div class="word-wrapper">
  <div class="word-top">
   <div class="home-header">
    <div class="header-searchword">
     <div class="goback" @click="$router.go(-1)">
      <i class="iconfont icon-back"></i>
     </div>
     <div class="search-msg need-xl-hidden">输入查新单词:</div>
     <el-input
      v-model="searchVal"
      class="word-input need-lg-hidden"
      @change="onSearch"
      placeholder="词汇需要被空格 分号; 逗号, 换行\  顿号、 隔开"
     ></el-input>
     <el-input
      v-model="searchVal"
      class="word-input need-xl-hidden"
      @keyup.enter.native="onSearch"
      placeholder="词汇需要被空格 分号; 逗号, 换行\  顿号、 隔开"
     ></el-input>
     <div class="search-icon need-xl-hidden" @click="onSearch">
      <!-- need-xl-hidden COMPUTER; need-lg-hidden MOBILE -->
      <i class="iconfont icon-sousuo"></i>
     </div>
     <div class="search-icon need-lg-hidden" @click="onSearch">
      <!-- need-xl-hidden COMPUTER; need-lg-hidden MOBILE -->
      <i class="iconfont icon-sousuo"></i>
     </div>
     <div class="camera-icon need-lg-hidden" @click="onClickCamera">
      <img src="../../assets/camera.svg" style="width: 35px; margin-top: 6px"/>
     </div>
     <div class="camera-icon need-xl-hidden" @click="onClickCamera">
      <img src="../../assets/camera.svg" style="width: 21px; margin-top: 6px"/>
     </div>

    </div>
    <div class="header-mybook need-xl-hidden" @click="$router.push('/book')">
     我的词汇本
    </div>
   </div>
  </div>
  <div class="word-content">
   <div class="word-main">
    <div class="main-title need-xl-hidden">
     <p class="title-text">{{ searchVal ? "搜索结果" : "精选词汇" }}</p>
     <div class="add-books" v-if="wordList.length" @click="onAllJoin">
      本页全部加入词汇本
     </div>
    </div>
    <div class="word-title need-lg-hidden">
     <p class="title-text">{{ searchVal ? "搜索结果" : "精选词汇" }}</p>
     <div class="header-mybook" @click="$router.push('/book')">我的词汇本</div>
    </div>
    <div class="word-list" v-if="wordList.length">
     <word
      v-for="(item, index) in wordList"
      :key="item.wordId"
      :index="index"
      :content="item"
      @join="onJoin"
     ></word>
    </div>
    <div class="word-pagination need-xl-hidden" v-if="wordList.length">
     <el-pagination
      @current-change="handleCurrentChange"
      :current-page="+currPage"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
     >
     </el-pagination>
    </div>
    <div class="word-pagination pagination need-lg-hidden">
     <div>
      <span>前往</span>
      <el-input
       type="text"
       v-model="currPage"
       onkeyup="value=value.replace(/[^\d]/g,'')"
       class="pagination-input"
       @change="onGoPage"
      ></el-input>
      <span>页</span>
     </div>
     <div class="change-page" @click="onPrev">上一页</div>
     <div class="change-page" @click="onNext">下一页</div>
    </div>
    <el-empty
     v-if="!wordList.length"
     :image="require('@/assets/round-nodata.jpg')"
     :image-size="200"
     description="没有搜到你想要的词汇，换个词试一试吧！"
    >
    </el-empty>

    <!--PC+Mobile -->
    <!-- 这个width比较特殊, 放在css里面就是不生效, 晚点找其他办法-->
    <el-dialog custom-class="ocr-dialog"
               title="拍照查词"
               :visible="dialogVisible"
               :before-close="handleDialogClose"
               :width="ifPc?'700px':'350px'"
    >
     <div class="ocr-main">
      <el-upload class="ocr-upload"
                ref="elUpload"
                action="https://wordfall.net/waterfall/recitation/ocr-words"
                :headers="{'Authorization':token}"
                :show-file-list="false"
                :preview="true"
                :multiple="false"
                :on-success="onUploadSuccess"
                :before-upload="ocrUploading"
      >
       <el-image v-if="imageUrl"
                 :src="imageUrl"
                 fit="contain">
       </el-image>
       <div v-if="!imageUrl">
        <img src="../../assets/upload.svg" style="width: 30px; margin-top: 20px"/>
        <span> 点此上传图片</span>
       </div>
      </el-upload>
      <div class="ocr-input">
       <span>待查词汇：</span>
       <el-input class="ipt" :rows="4" type="textarea" resize="none" v-model="ocrOutput"></el-input>
      </div>
     </div>
     <div slot="footer" class="dialog-footer">
      <div :class="ocrOutput?'confirm':'no-confirm'" @click="ocrConfirm">确认词汇</div>
      <div class="cancel" @click="handleDialogClose">取消</div>
     </div>
    </el-dialog>
   </div>
  </div>
 </div>
</template>

<script>
import word from "@/components/word";
import {getToken} from '@/utils';
import {
 recitationPostBook,
 recitationSearch,
 recitationSearchList,
} from "@/api/recitation";
import { Loading } from 'element-ui';

export default {
 data() {
  return {
   active: -1,
   wordList: [],
   searchVal: "",
   pageSize: 8,
   currPage: 1,
   total: 0,
   imageUrl: "",
   dialogVisible: false,
   ocrOutput: "",
   loadingInstance: null
  };
 },
 computed: {
  myTotal() {
   return Math.ceil(this.total / this.pageSize);
  },
  token() {
   return getToken();
  },
  ifPc() {
   return document.body.clientWidth>768
  }
 },
 methods: {
  onGoPage(val) {
   if (!+val) return;
   if (+val > this.myTotal) {
    this.currPage = this.myTotal;
    this.getWordList();
    return;
   }
   this.currPage = +val;
   this.getWordList();
  },
  onPrev() {
   if (this.currPage === 1) {
    return;
   }
   this.currPage--;
   this.getWordList();
  },
  onNext() {
   if (this.currPage === this.myTotal) {
    return;
   }
   this.currPage++;
   this.getWordList();
  },
  onAllJoin() {
   let wordIds = [];
   if (this.wordList && this.wordList.length > 0) {
    wordIds = this.wordList.filter((item)=>item.wordFamiliarityType === 0).map((item) => item.wordId);
   }
   if (wordIds.length === 0) {
    this.$message.success("已全部加入词汇本");
    return;
   }
   let req = {
    wordIds,
   };
   recitationPostBook(req).then((res) => {
    if (res) {
     this.handleCurrentChange(this.currPage);
     this.$message.success("加入成功");
    }
   });
  },
  onClickCamera() {
   this.dialogVisible = true;
   this.timer = setTimeout(() => {
    document.getElementsByClassName("el-upload__input")[0].click();
   }, 1);
  },
  onJoin(id) {
   let req = {
    wordIds: [id],
   };
   recitationPostBook(req).then((res) => {
    if (res) {
     this.handleCurrentChange(this.currPage);
     this.$message.success("加入成功");
    }
   });
  },
  handleDialogClose() {
   this.dialogVisible = false;
  },
  onUploadSuccess(response, file) {
   if (response.errCode !== '00') {
    this.$message.error(response.errMsg);
    this.loadingInstance.close();
    return;
   }
   this.imageUrl = URL.createObjectURL(file.raw);
   this.ocrOutput = response.data.map(x=>x.spelling).join(';');
   this.loadingInstance.close();
  },
  ocrConfirm() {
   this.searchVal = this.ocrOutput;
   this.handleDialogClose();
   this.onSearch();
  },
  onSearch() {
   if (!this.searchVal) {
    this.getWordList();
   } else {
    recitationSearch({ input: this.searchVal, pageNum: 1 }).then((res) => {
     this.total = res.data.total;
     this.wordList = res.data.list;
     this.$message.success("查词成功，共查到" + this.total + "个词汇");
    });
   }
  },
  getWordList() {
   recitationSearchList({ pageNum: this.currPage }).then((res) => {
    this.wordList = res.data.list;
    this.total = res.data.total;
    this.$message.success("查词成功，共查到" + this.total + "个词汇");
   });
  },
  onShowColl(idx) {
   if (this.active === idx) {
    this.active = -1;
   } else {
    this.active = idx;
   }
  },
  handleCurrentChange(val) {
   this.currPage = val;
   if (!this.searchVal) {
    this.getWordList();
   } else {
    recitationSearch({ input: this.searchVal, pageNum: this.currPage }).then((res) => {
     this.total = res.data.total;
     this.wordList = res.data.list;
    });
   }
  },
  ocrUploading(file) {
   if(file.size / 1024 / 1024 >= 5) {
    this.$message.error('上传图片文件过大');
    return;
   }
   this.loadingInstance = Loading.service({fullscreen: true, text: "识别中...", background: "202225A0"});
  }
 },
 created() {
  this.getWordList();
 },
 components: {
  word,
 },
};
</script>

<style lang="less" scoped>
.word-wrapper {
 width: 100%;
 background: #fafafa;
}
.pagination {
 display: flex;
 justify-content: space-between;
 font-size: 16px;
 /deep/ .el-input__inner,
 .change-page {
  width: 80px;
  border-radius: 30px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  border: 1px solid #f0f0f0;
 }
 /deep/ .el-input {
  width: 80px !important;
 }
 .pagination-input {
  margin: 0 10px;
 }
}
// PC STYLE
@media (min-width: 768px) and (max-width: 2640px) {
 .ocr-dialog {
  .ocr-main {
   margin: 0 25px 0 25px;
   box-sizing: border-box;
   border: dashed 2px #AFD5D3AA;
   .ocr-upload {
    height: 228px;
    div {
     width: 596px;
     height: 228px;
     font-size: 30px;
     text-align: center;
     line-height: 228px;
     color: #2C908C;
    }
    /deep/ .el-image__inner {
     height: 228px;
    }
   }
   .ocr-input {
    margin: 10px;
    display: flex;
    white-space: nowrap;
    color: #252525;
    align-items: center;
    .ipt {
     .el-textarea__inner {
      background-color: #f0f0f0;
      border-radius: 40px;
     }
    }
   }
  }
  .dialog-footer{
   display: flex;
   justify-content: center;
   //justify-content: space-between;
   // align-items: center;
   // box-sizing: border-box;
   .no-confirm {
    margin-right: 20px;
    border-radius: 30px;
    width: 240px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    background: #8c8c8c;
    cursor: pointer;
   }
   .confirm {
    margin-right: 20px;
    border-radius: 30px;
    width: 240px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    background: #2C908C;
    cursor: pointer;
   }
   .cancel {
    border-radius: 30px;
    border: 1px solid #499c96;
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #499c96;
    background: #ffffff;
    cursor: pointer;
   }
  }
 }

 .word-item-active {
  border: 1px solid #2c908c;
 }
 .word-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background: #ffffff;
  padding: 20px 0 12px 0;
  box-sizing: border-box;
  .home-header {
   width: 1200px;
   height: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 16px;
   .header-searchword {
    display: flex;
    align-items: center;
    color: #252525;
    .goback {
     width: 15px;
     height: 15px;
     line-height: 15px;
     margin-right: 20px;
     color: #8c8c8c;
     cursor: pointer;
    }
    .search-msg {
     margin-right: 8px;
    }
    .word-input {
     width: 700px;
     height: 36px;
     /deep/ .el-input__inner {
      border-radius: 42px;
      background: #f7f6f4;
      border: 1px solid #f0f0f0;
     }
    }
    .search-icon,.camera-icon {
     border-radius: 30px;
     background: #eaf0ef;
     width: 60px;
     height: 36px;
     margin-left: 20px;
     text-align: center;
     line-height: 36px;
     color: #2c908c;
     cursor: pointer;
    }
   }
   .header-mybook {
    border-radius: 30px;
    background: #2c908c;
    width: 140px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;
    cursor: pointer;
   }
  }
 }
 .word-content {
  background: #fafafa;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  .word-main {
   background: #fafafa;
   width: 1200px;
   border-radius: 20px;
   .word-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #ffffff;
    padding: 0 10px;
    box-sizing: border-box;
    .word-item {
     display: flex;
     justify-content: space-between;
     position: relative;
     width: 580px;
     border-radius: 20px;
     background: #f7f6f4;
     padding: 20px;
     box-sizing: border-box;
     height: 160px;
     margin-bottom: 20px;
     font-size: 12px;
     color: #252525;
     .item-collapse {
      position: absolute;
      top: 176px;
      left: 0;
      width: 100%;
      height: 236px;
      overflow: auto;
      border-radius: 20px;
      background: #eaf0ef;
      border: 1px solid #2c908c;
      box-shadow: 0px -8px 8px 0px #031b1d14;
      z-index: 99;
      padding: 20px;
      box-sizing: border-box;
      .collapse-text {
       color: #424242;
       font-size: 16px;
       line-height: 28px;
       text-align: left;
       margin: 0;
      }
     }
     &:nth-of-type(even) {
      margin-right: 0;
     }
     .item-left {
      .item-top {
       display: flex;
       margin-bottom: 10px;
       .item-name {
        font-size: 20px;
        color: #252525;
        margin-right: 12px;
       }
       .item-player {
        width: 18px;
        height: 18px;
        background: red;
        margin-right: 12px;
       }
       .item-newWords,
       .item-intimacy,
       .item-recite {
        width: 48px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 30px;
        color: #ffffff;
       }
       .item-newWords {
        background: #d06456;
       }
       .item-intimacy {
        background: #3382ca;
       }
       .item-recite {
        background: #bca371;
       }
      }
      .item-bs {
       margin-bottom: 14px;
       color: #595959;
       font-size: 14px;
      }
      .item-message {
       width: 360px;
       height: 56px;
       color: #424242;
       font-size: 16px;
       line-height: 28px;
       display: -webkit-box;
       -webkit-box-orient: vertical;
       -webkit-line-clamp: 2;
       overflow: hidden;
      }
     }
     .item-right {
      display: flex;
      flex-direction: column;

      .item-progress {
       display: flex;
       align-items: center;
       margin-bottom: 26px;
       .item-msg {
        font-size: 14px;
        margin-right: 16px;
       }
      }
      .item-btns {
       display: flex;
       justify-content: flex-end;
       .item-join,
       .item-already-join {
        border-radius: 30px;
        height: 36px;
        font-size: 16px;
        text-align: center;
        line-height: 36px;
       }
       .item-join {
        width: 80px;
        background: #2c908c;
        color: #ffffff;
       }
       .item-already-join {
        width: 88px;
        background: #eaf0ef;
        color: #2c908c;
       }
      }
     }
    }
   }
   .word-pagination {
    display: flex;
    justify-content: center;
    background: #ffffff;
   }
   .title-text {
    color: #252525;
    font-size: 20px;
   }
   .main-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    .add-books {
     border-radius: 30px;
     border: 1px solid #499c96;
     width: 180px;
     height: 36px;
     text-align: center;
     line-height: 36px;
     color: #499c96;
     background: #ffffff;
     cursor: pointer;
    }
   }
  }
 }
}

// MOBILE STYLE
@media (max-width: 768px) {
 .ocr-dialog {
  .ocr-main {
   margin: 0 10px 0 10px;
   box-sizing: border-box;
   border: dashed 2px #AFD5D3AA;
   .ocr-upload {
    height: 128px;
    div {
     width: 296px;
     //height: 128px;
     font-size: 24px;
     text-align: center;
     line-height: 128px;
     color: #2C908C;
    }
    /deep/ .el-image__inner {
     height: 128px;
    }
   }
   .ocr-input {
    margin: 10px;
    display: flex;
    white-space: nowrap;
    color: #252525;
    align-items: center;
    .ipt {
     .el-textarea__inner {
      background-color: #f0f0f0;
      border-radius: 40px;
     }
    }
   }
  }
  .dialog-footer{
   display: flex;
   justify-content: center;
   .no-confirm {
    margin-right: 20px;
    border-radius: 30px;
    width: 240px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    background: #8c8c8c;
    cursor: pointer;
   }
   .confirm {
    margin-right: 20px;
    border-radius: 30px;
    width: 240px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    background: #2C908C;
    cursor: pointer;
   }
   .cancel {
    border-radius: 30px;
    border: 1px solid #499c96;
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    color: #499c96;
    background: #ffffff;
    cursor: pointer;
   }
  }
 }
 .word-top {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 10px;
  box-sizing: border-box;
  .home-header {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 16px;
   .header-searchword {
    display: flex;
    width: 100%;
    align-items: center;
    color: #252525;
    .goback {
     width: 22px;
     height: 22px;
     margin-right: 10px;
     color: #8c8c8c;
     cursor: pointer;
    }
    .search-msg {
     margin-right: 8px;
    }
    .word-input {
     box-sizing: border-box;
     //flex: auto;
     /deep/ .el-input__inner {
      border-radius: 42px;
      background: #f7f6f4;
      border: 1px solid #f0f0f0;
     }
    }
    
    .search-icon {
     border-radius: 30px;
     background: #eaf0ef;
     width: 60px;
     height: 36px;
     margin-left: 10px;
     text-align:center;
     line-height: 36px;
     color: #2c908c;
    }
    .camera-icon {
     border-radius: 30px;
     //background: #eaf0ef;
     width: 50px;
     height: 50px;
     margin-left: 10px;
    }

   }
   .header-mybook {
    border-radius: 30px;
    background: #2c908c;
    width: 140px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;
   }
  }
 }
 .word-content {
  background: #fafafa;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  .word-main {
   margin-top: 10px;
   background: #fafafa;
   border-radius: 10px;
   width: 100%;
   .word-list {
    .word-item {
     width: 100%;
     display: flex;
     justify-content: space-between;
     position: relative;
     border-radius: 10px;
     background: #f7f6f4;
     padding: 10px;
     box-sizing: border-box;
     height: 160px;
     margin-bottom: 17px;
     font-size: 12px;
     color: #252525;
     .item-collapse {
      position: absolute;
      top: 164px;
      left: 0;
      width: 100%;
      height: 250px;
      overflow: auto;
      border-radius: 10px;
      background: #eaf0ef;
      border: 1px solid #2c908c;
      z-index: 99;
      padding: 10px;
      box-sizing: border-box;
      .collapse-text {
       color: #424242;
       font-size: 14px;
       line-height: 28px;
       text-align: left;
       margin: 0;
      }
     }
     .item-left {
      .item-top {
       display: flex;
       margin-bottom: 10px;
       .item-name {
        font-size: 20px;
        color: #252525;
        margin-right: 12px;
       }
       .item-player {
        width: 18px;
        height: 18px;
        background: red;
        margin-right: 12px;
       }
       .item-newWords,
       .item-intimacy,
       .item-recite {
        width: 48px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 30px;
        color: #ffffff;
       }
       .item-newWords {
        background: #d06456;
       }
       .item-intimacy {
        background: #3382ca;
       }
       .item-recite {
        background: #bca371;
       }
      }
      .item-bs {
       margin-bottom: 14px;
       color: #595959;
       font-size: 14px;
      }
      .item-message {
       width: 190px;
       height: 56px;
       color: #424242;
       font-size: 16px;
       line-height: 28px;
       display: -webkit-box;
       -webkit-box-orient: vertical;
       -webkit-line-clamp: 2;
       overflow: hidden;
      }
     }
     .item-right {
      display: flex;
      flex-direction: column;

      .item-progress {
       display: flex;
       align-items: center;
       margin-bottom: 26px;
       .item-msg {
        font-size: 14px;
        margin-right: 16px;
       }
      }
      .item-btns {
       display: flex;
       justify-content: flex-end;
       .item-join,
       .item-already-join {
        border-radius: 30px;
        height: 36px;
        font-size: 16px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
       }
       .item-join {
        width: 80px;
        background: #2c908c;
        color: #ffffff;
       }
       .item-already-join {
        width: 88px;
        background: #eaf0ef;
        color: #2c908c;
       }
      }
     }
    }
   }
   .word-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    .title-text {
     color: #252525;
     font-size: 20px;
    }
    .header-mybook {
     border-radius: 30px;
     background: #2c908c;
     width: 140px;
     height: 36px;
     text-align: center;
     line-height: 36px;
     color: #ffffff;
    }
   }
  }
 }
}
</style>

<style>
.el-dialog.ocr-dialog {
 box-sizing: border-box;
 border: solid 1px #2C908C;
 border-radius: 20px;
}
</style>