<template>
  <div class="dialog-wrapper" v-if="visible">
    <div class="dialog-content">
      <div class="content-left"></div>
      <div class="content-right">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(32, 34, 37, 0.6);
  z-index: 100;
  overflow: auto;
}
@media (min-width: 768px) and (max-width: 2640px) {
  .dialog-content {
    margin: 0 auto;
    margin-top: 10vh;
    width: 700px;
    border-radius: 20px;
    background: #ffffff;
    z-index: 101;
    display: flex;
    .content-left {
      width: 45%;
      border-radius: 20px 0px 0px 20px;
      background-image: url("../assets/left-banner.jpg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .content-right {
      width: 100%;
      height: 100%;
    }
  }
}
@media (max-width: 768px) {
  .dialog-content {
    margin: 0 auto;
    margin-top: 10vh;
    width: 335px;
    // width: 90%;
    border-radius: 10px;
    background: #ffffff;
    z-index: 101;
    display: flex;
    .content-left {
      display: none;
    }
    .content-right {
      width: 100%;
      height: 100%;
    }
  }
}
</style>