import request from  '@/utils/request'

export function recitationRound() {
    return request({
        url: "/waterfall/recitation/recitative-round",
        method: "get"
    })
}

export function recitationPostRound(data) {
    return request({
        url: "/waterfall/recitation/recitative-round",
        method: "post",
        data
    })
}

export function recitationTopic() {
    return request({
        url: "/waterfall/recitation/recitative-topic",
        method: "get"
    })
}

export function recitationPutTopic(data) {
    return request({
        url: "/waterfall/recitation/recitative-topic",
        method: 'put',
        data
    })
}

export function recitationNextTopic() {
    return request({
        url: "/waterfall/recitation/next-recitative-topic",
        method: "get"
    })
}

export function recitationWord() {
    return request({
        url: "/waterfall/recitation/recitative-word",
        method: "get"
    })
}

export function recitationSearchList(data) {
    return request({
        url: "/waterfall/recitation/push-list",
        method: "get",
        params:data
    })
}

export function recitationSearch(data) {
    return request({
        url: "/waterfall/recitation/search",
        method: "post",
        data
    })
}

export function recitationBook(data) {
    return request({
        url: "/waterfall/recitation/book",
        method:"get",
        params: data
    })
}

export function recitationPostBook(data) {
    return request({
        url: "/waterfall/recitation/book",
        method: "post",
        data
    })
}

export function recitationFamiliar(data) {
    return request({
        url: "/waterfall/recitation/familiar",
        method: "put",
        data
    })
}

export function recitationUnFamiliar(data) {
    return request({
        url: "/waterfall/recitation/unfamiliar",
        method: "put",
        data
    })
}