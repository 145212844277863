import router from './router'
import { getToken,removeToken } from '@/utils'

router.beforeEach((to, from, next) => {
    const hasToken = getToken();
    if(to.path === '/login') {
        if(hasToken) {
            // removeToken();
            next({path: "/home"})
        }
        next()
    }else {
        if(hasToken) {
            next()
        }else {
            next({path: "/login"})
        }
    }
})