<template>
  <div class="details-wrapper">
    <div class="details-top">
      <div class="details-header">
        <div class="gohome" @click="$router.push('/home')">
          <i class="iconfont icon-a-shouye_home2"></i>
        </div>
        <div class="network">
          <!-- dian-duan -->
          <div class="dian"></div>
          {{ workTxt }}
        </div>
      </div>
    </div>
    <div class="details-content">
      <div class="details-main">
        <div class="main-left">
          <div class="details-type">
            <img src="../../assets/题型.png" alt="" class="icon" />
            <Title :topicType="currTopicType" />
          </div>
          <div class="details-ques">
            <span v-html="ques" style="white-space: pre-wrap"></span>
            <span v-if="currTopicType === 1">
              <span
                v-for="(item, index) in pronunciations"
                :key="index"
                @click="onPlay('ques', index)"
              >
                <i class="iconfont icon-yinpin icon"></i>
                <audio
                  v-for="(item, index) in pronunciations"
                  :src="item"
                  hidden
                  controls="controls"
                  :ref="'ques' + index"
                ></audio>
              </span>
            </span>
          </div>
          <div class="details-options">
            <p class="options-title" v-if="!isType5">
              请选择
              <span class="need-xl-hidden"
                >（鼠标点击 或 按键盘1、2、3、4）</span
              >
            </p>
            <p class="options-title" v-else>请输入</p>
            <div v-if="!isType5">
              <div
                class="options-item"
                v-for="(item, index) in options"
                :class="[
                  item.isErr ? 'error' : '',
                  item.isSucc ? 'success' : '',
                ]"
                :key="item.wordId"
                @click="onGetWordId(item, index)"
                :style="otherType ? 'height:60px;' : ''"
              >
                <span
                  class="item-idx"
                  :class="item.isErr || item.isSucc ? 'write' : ''"
                  >{{ index + 1 }}.</span
                >
                <div v-if="!(item.isErr || item.isSucc)">
                  <div class="item-content" v-if="currTopicType === 1">
                    <div v-for="el in item.contents" :key="el.abbr">
                      {{ el.abbr }} {{ el.translationCn }}
                    </div>
                  </div>
                  <div class="item-content" v-if="otherType">
                    {{ item.spelling }}
                  </div>
                </div>
                <div class="options-answer" v-else>
                  <div v-if="item.answerInfo.reads.length > 0">
                    <span v-for="(item, j) in item.answerInfo.reads" :key="j">
                      <span>{{ item.abbr }}</span>
                      <span
                        v-if="item.pronunciation"
                        @click="onPlay(`options${index}`, j)"
                      >
                        <i class="iconfont icon-yinpin icon"></i>
                        <audio
                          :src="item.pronunciation"
                          hidden
                          controls="controls"
                          :ref="'options' + index + j"
                        ></audio>
                      </span>
                    </span>
                  </div>
                  <div
                    v-if="item.answerInfo.properties.length > 0"
                    :class="otherType ? 'text-flow-1' : 'text-flow-3'"
                  >
                    <span
                      v-for="(item, index) in item.answerInfo.properties"
                      :key="index"
                      >{{ item.abbr }}{{ item.translationCn }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="options-type5" v-else>
              <div class="options-input">
                <el-input
                  v-model="topicVal"
                  @input="onChange"
                  @keyup.enter.native="onSubmitTopic"
                  placeholder="here，type the expression！"
                  :class="type5Class"
                  ref="topicIpt"
                ></el-input>
                <!--  -->
                <button
                  class="options-submit"
                  @click="onSubmitTopic"
                  :disabled="submitDis"
                >
                  确定
                </button>
              </div>
              <div class="error-info">
                <p v-if="errorTxt" class="error-txt">{{ errorTxt }}</p>
                <div
                  class="error-content"
                  v-if="Object.keys(errorInfo).length !== 0"
                >
                  <h3>
                    {{ errorInfo.spelling }}
                    <audio
                      v-for="(item, index) in errorInfo.reads"
                      :src="item.pronunciation"
                      :key="index"
                      :ref="'error' + index"
                    ></audio>
                  </h3>
                  <div
                    v-for="(item, index) in errorInfo.properties"
                    :key="index"
                  >
                    <span>{{ item.abbr }}</span>
                    <span>{{ item.translationCn }}</span>
                    <span>{{ item.translationEn }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-right">
          <div class="details-time">
            <div class="time-left need-xl-hidden">
              <!-- <span class="icon"></span> -->
              <img src="../../assets/时间.png" alt="" class="icon" />
              <span>时间</span>
            </div>
            <div class="need-lg-hidden icon-time"></div>
            <div class="time-num">{{ timeCount }}</div>
          </div>
          <div class="details-example">
            <div class="example-top">
              <div class="example-item">
                <img src="../../assets/助记.png" alt="" class="icon" />
                <span>助记信息</span>
              </div>
              <div class="example-item" v-if="currTopicType !== 2">
                <span>例句</span>
                <el-switch
                  v-model="value"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </div>
            </div>
            <div class="example-content">
              <div v-if="exp">
                <div v-for="item in exp.properties" :key="item.abbr">
                  <p class="exp-text">{{ item.abbr }}</p>
                  <div
                    v-for="(ele, j) in item.definitions"
                    :key="j"
                    style="white-space: pre-wrap"
                  >
                    <span>{{ j + 1 }}.</span>
                    <span v-if="ele.translationCn">{{
                      ele.translationCn
                    }}</span>
                    <span v-if="ele.translationEn">{{
                      ele.translationEn
                    }}</span>
                    <div v-if="currTopicType === 2 || value" style="white-space: pre-wrap">
                      <span v-for="(x, y) in ele.sentences" :key="y">
                        <span v-if="x.sentenceEn">· {{ x.sentenceEn }}</span>
                        <span v-if="x.sentenceEnBlank">· {{
                          x.sentenceEnBlank
                        }}</span>
                        <span v-if="x.sentenceCn">  {{ x.sentenceCn }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="details-progress">
            <div class="progress-top">
              <img src="../../assets/进度.png" alt="" class="icon" />
              <span>进度</span>
            </div>
            <div class="progress-list">
              <div
                class="list-item"
                v-for="item in wordProgresses"
                :key="item.wordId"
              >
                <div class="item-one">
                  <img v-if="item.cover" :src="item.cover" alt="" />
                </div>
                <div class="items" v-for="(el, j) in item.states" :key="j">
                  <img
                    src="../../assets/weiwancheng.svg"
                    v-if="el === 0"
                    alt=""
                  />
                  <img src="../../assets/wancheng.svg" v-if="el === 1" alt="" />
                  <img src="../../assets/cuowu.svg" v-if="el === 2" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  recitationTopic,
  recitationPutTopic,
  recitationNextTopic,
  recitationRound,
} from "@/api/recitation";
import Title from "./components/title.vue";
import { getCurrIndex } from "@/utils/dom";
import { setIsFinish } from "@/utils";
export default {
  data() {
    return {
      workTxt: "",
      value: false,
      pronunciations: [],
      options: [],
      currTopicType: null,
      currTopicId: null,
      exp: null,
      ques: "",
      currWordId: 0,
      currIndex: 0,
      currCode: 0,
      currSpelling: "",
      hasCorrect: false,
      minutes: 0,
      seconds: 0,
      duration: null,
      timer: null,
      timeCount: "00:00",
      topicVal: "",
      errorTxt: "",
      submitDis: false,
      nextInfo: null,
      hasIsFinish: false,
      hasNext: true,
      errorInfo: {},
      wordProgresses: [],
      canClick: true,
    };
  },
  watch: {
    netWork: {
      handler(val) {
        let status;
        this.workTxt = val ? "网络已连接" : "网络已断开";
        status = val ? "success" : "error";
        // this.$message[status](this.workTxt);
      },
      immediate: true,
    },
  },
  computed: {
    otherType() {
      return (
        this.currTopicType === 2 ||
        this.currTopicType === 3 ||
        this.currTopicType === 4
      );
    },
    isType5() {
      return this.currTopicType === 5;
    },
    getTime() {
      return this.minutes * (60 * 1000) + this.seconds * 1000;
    },
    type5Class() {
      return Object.keys(this.errorInfo).length === 0
        ? ""
        : this.hasCorrect
        ? "success"
        : "error";
    },
  },
  methods: {
    onChange(val) {
      if (val) {
        this.errorTxt = "";
      }
    },
    onSubmitTopic() {
      if (!this.topicVal) {
        this.errorTxt = "输入答案不能为空";
        return;
      }
      let req = {
        duration: this.getTime,
        spelling: this.topicVal,
        reciteRoundTopicId: this.currTopicId,
      };
      this.sendRecitationTopic(req);
    },
    onGetWordId(val, idx) {
      let req = null;
      if (val && idx) {
        this.currIndex = idx;
        this.currWordId = val.wordId;
        this.currSpelling = val.spelling;
        if (val.isSucc || val.isErr) return;
        if (!this.canClick) return;
        this.canClick = false;
        req = {
          duration: this.getTime,
          reciteRoundTopicId: this.currTopicId,
          spelling: this.currSpelling,
          wordId: this.currWordId,
        };
        this.sendRecitationTopic(req);
      } else {
        this.currIndex = getCurrIndex(this.currCode);
        let currVal = this.options[this.currIndex];
        console.log(currVal,this.currIndex,this.options);
        this.currWordId = currVal.wordId;
        this.currSpelling = currVal.spelling;
        if (currVal.isSucc || currVal.isErr) return;
        if (!this.canClick) return;
        this.canClick = false;
        req = {
          duration: this.getTime,
          reciteRoundTopicId: this.currTopicId,
          spelling: this.currSpelling,
          wordId: this.currWordId,
        };
        this.sendRecitationTopic(req);
      }
    },
    onPlay(type, idx) {
      this.$nextTick(() => {
        let tit = `${type}${idx}`;
        this.$refs[tit][idx].play();
      });
    },
    getRecitationRound() {
      recitationRound().then((res) => {
        this.wordProgresses = res.data.wordProgresses;
      });
    },
    getRecitationTopic() {
      recitationTopic().then((res) => {
        if (res) {
          this.resetCurrData(res.data);
          if (this.hasNext) {
            this.getRecitationNextTopic();
          }
          this.getRecitationRound();
        }
      });
    },
    getRecitationNextTopic() {
      this.currIndex = 0;
      this.currCode = 0;
      this.currWordId = 0;
      this.currSpelling = "";
      this.hasCorrect = false;
      recitationNextTopic().then((res) => {
        if (res) {
          let values = Object.values(res.data).filter(Boolean);
          if (values.length === 0) {
            this.hasNext = false;
          }
          this.nextInfo = res.data;
          this.getRecitationRound();
        }
      });
    },
    resetCurrData(data) {
      if (this.currTopicType === 5) {
        this.errorInfo = {};
        this.topicVal = "";
      }
      if (data.options && Array.isArray(data.options)) {
        let opts = [...data.options];
        opts.forEach((item) => {
          item.isSucc = false;
          item.isErr = false;
          item.answerInfo = {
            reads: [],
            properties: [],
          };
        });
        this.options = opts;
      }
      this.currTopicType = data.topicType;
      this.ques = data.question;
      this.exp = data.tip;
      this.pronunciations = data.pronunciations;
      if (this.currTopicType === 1) {
        this.pronunciations.forEach((item, index) => {
          this.$nextTick(() => {
            console.log(item, this.$refs);
          });
          setTimeout(() => this.onPlay('ques', index), index * 2000)
        });
      } else if (this.currTopicType === 5) {
        this.$nextTick(() => {
          this.$refs.topicIpt.$refs.input.focus();
        })
      }

      this.currTopicId = data.reciteRoundTopicId;
      this.duration = data.duration;
      this.formDuration(this.duration);
      clearInterval(this.timer);
      this.timer = null;
      this.startDuration();
    },
    formDuration(mss) {
      var minutes = parseInt((mss % (1000 * 60 * 1000)) / (1000 * 60));
      var seconds = parseInt((mss % (1000 * 60)) / 1000);
      this.seconds = seconds;
      this.minutes = minutes;
    },
    startTimer() {
      this.seconds += 1;
      if (this.seconds >= 60) {
        this.seconds = 0;
        this.minutes = this.minutes + 1;
      }
      this.timeCount =
        (this.minutes < 10 ? "0" + this.minutes : this.minutes) +
        ":" +
        (this.seconds < 10 ? "0" + this.seconds : this.seconds);
    },
    startDuration() {
      this.timer = setInterval(() => {
        this.startTimer();
      }, 1000);
    },
    sendRecitationTopic(req) {
      recitationPutTopic(req).then((res) => {
        this.hasCorrect = !!res.data.isCorrect;
        this.hasIsFinish = !!res.data.isFinish;
        let hint = res.data.hint;
        if (this.currTopicType !== 5) {
          this.$set(
            this.options[this.currIndex].answerInfo,
            "reads",
            res.data.reads
          );
          this.$set(
            this.options[this.currIndex].answerInfo,
            "properties",
            res.data.properties
          );
        } else {
          this.errorInfo = res.data;
          if (!this.hasCorrect) {
            if (hint !== '' && hint != null) {
             this.$message.info(hint);
            }
            this.topicVal = "";
            this.errorTxt = "答案错误";
            this.$refs.topicIpt.$refs.input.focus();
          }
        }
        if (this.hasCorrect) {
          if (this.currTopicType !== 5) {
            this.options[this.currIndex].isSucc = true;
            if (this.options[this.currIndex].answerInfo.reads.length !== 0) {
              let arr = JSON.parse(
                JSON.stringify(this.options[this.currIndex].answerInfo.reads)
              ).map((item) => item.pronunciation);
              this.createAudio(arr);
              if (!this.canClick) {
                this.canClick = true;
              }
            }
          } else {
            if (this.errorInfo && this.errorInfo.reads) {
              let arr = JSON.parse(JSON.stringify(this.errorInfo.reads)).map(
                (item) => item.pronunciation
              );
              this.createAudio(arr);
              if (!this.canClick) {
                this.canClick = true;
              }
            } 
          }
        } else {
          if (this.currTopicType !== 5) {
            this.options[this.currIndex].isErr = true;
            if (this.options[this.currIndex].answerInfo.reads.length !== 0) {
              let arr = JSON.parse(
                JSON.stringify(this.options[this.currIndex].answerInfo.reads)
              ).map((item) => item.pronunciation);
              this.createAudio(arr);
              if (!this.canClick) {
                this.canClick = true;
              }
            }
          }
        }
      });
    },
    createAudio(arr) {
      let self = this;
      let audio = document.createElement("audio");
      audio.src = arr.shift();
      audio.controls = "";
      audio.hidden = true;
      audio.addEventListener("ended", function () {
        if (arr.length === 0) {
          document.body.removeChild(audio);
          self.canClick = true;
          self.changeNextStep();
          return;
        }
        audio.src = arr.shift();
        audio.play();
      });
      audio.play();
      document.body.appendChild(audio);
    },
    changeNextStep() {
      if (this.hasIsFinish) {
        this.$router.push("/recite");
        setIsFinish();
        return;
      }
      if (this.hasCorrect) {
        setTimeout(() => {
          if (this.nextInfo) {
            this.resetCurrData(this.nextInfo);
            if (this.hasNext) {
              this.getRecitationNextTopic();
            } else {
              this.getRecitationTopic();
            }
          }
        }, 1000);
      }
    },
  },
  created() {
    this.getRecitationTopic();
  },
  mounted() {
    let self = this;
    document.addEventListener("keyup", function () {
      if (self.currTopicType !== 5) {
        const CODES = [49, 50, 51, 52];
        let key = window.event.keyCode;
        if (CODES.includes(key)) {
          self.currCode = key;
          self.onGetWordId(null);
        }
      }
    });
  },
  components: {
    Title,
  },
};
</script>

<style lang="less" scoped>
.book-wrapper {
  width: 100%;
  background: #fafafa;
}
.write {
  color: #ffffff !important;
}

.dian {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
  background: #2c908c;
}
.dian-duan {
  background: #d06456;
}
.network {
  display: flex;
  align-items: center;
}
@media (min-width: 768px) and (max-width: 2640px) {
  .icon {
    width: 22px;
    height: 22px;
    margin-right: 12px;
  }

  .details-top {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    .details-header {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #252525;
      padding: 0 20px;
      box-sizing: border-box;
      .gohome {
        width: 60px;
        height: 36px;
        border-radius: 30px;
        cursor: pointer;
        line-height: 36px;
        text-align: center;
        color: #2c908c;
        background: #eaf0ef;
      }
    }
  }
  .details-content {
    background: #fafafa;
    display: flex;
    justify-content: center;
    .details-main {
      width: 1200px;
      padding: 0 10px;
      box-sizing: border-box;
      background: #ffffff;
      display: flex;
      margin-top: 20px;
      .main-left,
      .main-right {
        flex: 1;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background: #ffffff;
      }
      .main-left {
        margin-right: 20px;
        .details-type {
          display: flex;
          align-items: center;
          color: #0d4141;
          font-size: 18px;
          margin-bottom: 18px;

          p {
            margin: 0;
          }
        }
        .details-ques {
          border-radius: 20px;
          background: #eaf0ef;
          display: flex;
          align-items: center;
          padding: 24px 20px;
          box-sizing: border-box;
          margin-bottom: 19px;
          font-size: 24px;
          span {
            margin-right: 10px;
          }
          .icon {
            color: #2c908c;
            cursor: pointer;
            font-size: 22px;
          }
        }
        .details-options {
          .options-title {
            margin: 19px 0;
            color: #8c8c8c;
            font-size: 18px;
          }
          .options-item {
            border-radius: 20px;
            background: #f7f6f4;
            height: 130px;
            margin-bottom: 20px;
            padding: 12px 0 12px 20px;
            color: #252525;
            font-size: 16px;
            box-sizing: border-box;
            display: flex;
            .item-idx {
              color: #8c8c8c;
              font-size: 16px;
              margin-right: 10px;
            }
            &.success {
              background: #87ab4b;
              color: #fff;
            }
            &.error {
              background: #d06456;
              color: #fff;
            }
          }
          .options-input {
            display: flex;

            /deep/ .el-input__inner {
              width: 440px;
              height: 36px;
              border-radius: 42px;
              background: #f7f6f4;
            }
            .success {
              /deep/ .el-input__inner {
                background: #e8ebdd !important;
                color: #252525;
              }
              /deep/ .el-input__inner:focus {
                border-color: #2c908c !important;
              }
            }
            .error {
              /deep/ .el-input__inner {
                background: #fff4f0 !important;
                color: #252525;
              }
              /deep/ .el-input__inner:focus {
                border-color: #fff4f0 !important;
              }
            }

            .options-submit {
              width: 80px;
              height: 36px;
              border-radius: 30px;
              height: 36px;
              background: #eaf0ef;
              color: #2c908c;
              cursor: pointer;
            }
          }
          .error-info {
            padding-left: 20px;
            .error-txt {
              color: #d06456;
            }
          }
        }
      }
      .main-right {
        .details-time {
          border-radius: 20px;
          height: 60px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .time-left,
          .time-num {
            display: flex;
            align-items: center;
            font-size: 18px;
          }
          .time-left {
            width: 116px;
          }
          .time-num {
            justify-content: center;
            flex: 1;
            color: #6aa8a2;
            font-size: 32px;
          }
        }
        .details-example {
          font-size: 16px;
          .example-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            font-size: 18px;
            height: 60px;
          }
          .example-item {
            display: flex;
            align-items: center;
            span {
              margin-right: 8px;
            }
          }
          .example-content {
            line-height: 32px;
            .exp-text {
              margin: 0;
            }
          }
        }
        .details-progress {
          padding-top: 20px;
          box-sizing: border-box;
          height: 240px;
          border-radius: 20px;
          .progress-top {
            display: flex;
            align-items: center;
          }
          .progress-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-top: 18px;
            .list-item {
              width: 240px;
              height: 40px;
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              justify-content: space-between;
              .item-one {
                border-radius: 8px;
                background: #e9dbc8;
                width: 40px;
                height: 100%;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 8px;
                  object-fit: cover;
                }
              }
              .items {
                width: 28px;
                height: 28px;
                img {
                  width: 100%;
                  height: 100%;
                }
                // margin: 0 12px;
                // &:last-child {
                //   margin: 0;
                // }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .icon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .details-top {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    .details-header {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #252525;
      .gohome {
        width: 22px;
        height: 22px;
        cursor: pointer;
        line-height: 22px;
        text-align: center;
        color: #2c908c;
      }
    }
  }
  .details-content {
    display: flex;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    .details-main {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      background: #ffffff;
      .main-left {
        margin-bottom: 10px;
        .details-type {
          display: flex;
          align-items: center;
          color: #0d4141;
          font-size: 14px;
          margin-bottom: 12px;
          p {
            margin: 0;
          }
        }
        .details-ques {
          border-radius: 10px;
          background: #eaf0ef;
          display: flex;
          align-items: center;
          padding: 10px;
          font-size: 16px;
          box-sizing: border-box;
          span {
            margin-right: 10px;
          }
          .icon {
            color: #2c908c;
            cursor: pointer;
            font-size: 16px;
          }
        }
        .details-options {
          .options-title {
            margin: 10px 0;
            color: #8c8c8c;
            font-size: 14px;
          }
          .options-item {
            width: 100%;
            border-radius: 10px;
            background: #f7f6f4;
            height: 130px;
            margin-bottom: 10px;
            padding: 10px;
            color: #252525;
            font-size: 14px;
            box-sizing: border-box;
            display: flex;
            // line-height: 32px;
            .item-idx {
              color: #8c8c8c;
              font-size: 16px;
              margin-right: 5px;
            }
            &.success {
              background: #87ab4b;
              color: #fff;
            }
            &.error {
              background: #d06456;
              color: #fff;
            }
          }
          .options-input {
            display: flex;

            /deep/ .el-input__inner {
              width: 265px;
              height: 36px;
              border-radius: 20px;
              background: #f7f6f4;
            }
            .success {
              /deep/ .el-input__inner {
                background: #e8ebdd !important;
                color: #252525;
              }
              /deep/ .el-input__inner:focus {
                border-color: #2c908c !important;
              }
            }
            .error {
              /deep/ .el-input__inner {
                background: #fff4f0 !important;
                color: #252525;
              }
              /deep/ .el-input__inner:focus {
                border-color: #fff4f0 !important;
              }
            }

            .options-submit {
              width: 80px;
              height: 36px;
              border-radius: 32px;
              height: 36px;
              background: #eaf0ef;
              color: #2c908c;
              cursor: pointer;
            }
          }
          .error-info {
            padding-left: 20px;
            .error-txt {
              color: #d06456;
            }
          }
        }
      }
      .main-right {
        .details-time {
          border-radius: 10px;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
          color: #6aa8a2;
          position: relative;
          .icon-time {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 22px;
            height: 22px;
          }
        }
        .details-example {
          font-size: 16px;
          margin-bottom: 20px;
          .example-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            font-size: 18px;
            height: 60px;
          }
          .example-item {
            display: flex;
            align-items: center;
            .icon {
              width: 28px;
              height: 28px;
            }
            span {
              margin-right: 8px;
            }
          }
          .example-content {
            line-height: 32px;
            .exp-text {
              margin: 0;
            }
          }
        }
        .details-progress {
          box-sizing: border-box;
          border-radius: 10px;
          .progress-top {
            display: flex;
            align-items: center;
            height: 44px;
            .icon {
              margin-right: 8px;
              width: 28px;
              height: 28px;
            }
          }
          .progress-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .list-item {
              width: 100%;
              height: 80px;
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              justify-content: space-between;
              .item-one {
                border-radius: 8px;
                background: #e9dbc8;
                width: 80px;
                height: 100%;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 8px;
                  object-fit: cover;
                }
              }
              .items {
                width: 40px;
                height: 40px;
                img {
                  width: 100%;
                  height: 100%;
                }
                // margin: 0 12px;
                // &:last-child {
                //   margin: 0;
                // }
              }
            }
          }
        }
      }
    }
  }
}
</style>