<template>
  <div class="recite-wrapper">
    <div class="recite-top">
      <div class="recite-header">
        <div class="recite-left need-xl-hidden">
          <div class="goback" @click="onLink">
            <i class="iconfont icon-back"></i>
          </div>
          <div class="recite-msg">待背诵生词{{ waitWords }}个</div>
        </div>
        <div class="go-xl-back need-lg-hidden" @click="onLink">
          <i class="iconfont icon-back"></i>
        </div>
        <div class="recite-center">
          <p>
            本组生词<span>{{ currWords }}</span
            >个，
          </p>
          <p>
            待练习 <span>{{ waitTopics }}</span> 题
          </p>
        </div>
      </div>
    </div>
    <div class="recite-content">
      <div class="result-main" v-if="this.hasFinsh && this.reciteList.length === 0">
        <div class="recite-result">
          <p class="result-text need-xl-hidden">{{ resultTxt }}</p>
          <div class="result-text need-lg-hidden">
            <p>太强了！您已完美完成上组词汇挑战，</p>
            <p>速速开启新挑战吧！</p>
          </div>
        </div>
        <div class="go-link">
          <div class="go-home" @click="$router.push('/home')">
            <i class="iconfont icon-a-shouye_home2"></i>
          </div>
          <div class="go-recite" @click="$router.push('/book')">去查词</div>
        </div>
      </div>
      <div class="recite-main" v-else>
        <div class="recite-result" v-if="this.hasFinsh">
          <p class="result-text need-xl-hidden">{{ resultTxt }}</p>
          <div class="result-text need-lg-hidden">
            <p>太强了！您已完美完成上组词汇挑战，</p>
            <p>速速开启新挑战吧！</p>
          </div>
        </div>
        <div class="recite-list">
          <Item
            v-for="(item, index) in currRecites"
            :key="item.wordId"
            :content="item"
            :index="index"
            :needToggle="needToggle"
            @toggle="onToggle"
          />
        </div>
        <div class="recite-bottom">
          <div
            class="recite-start"
            :class="hasRound ? 'recite-on' : ''"
            @click="onSaveRecite"
          >
            {{ RoundBtnTxt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from "@/components/item";
import {
  recitationWord,
  recitationRound,
  recitationPostRound,
} from "@/api/recitation";
import { getIsFinish,removeIsFinish, MAXLEN } from "@/utils";
export default {
  data() {
    return {
      hasRound: false,
      reciteList: [],
      currRecites: [],
      resRecites: [],
      count: 0,
      hasFinsh: 0,
    };
  },
  computed: {
    resultTxt() {
      if (this.hasFinsh) {
        return "太强了！您已完美完成上组词汇挑战，速速开启新挑战吧！";
      }
      if (this.hasFinsh && this.reciteList.length === 0) {
        return "太强了！您已完美完成全部词汇挑战！";
      }
      return "";
    },
    needToggle() {
      return this.resRecites.length > 0;
    },
    waitWords() {
      return this.reciteList.length;
    },
    currWords() {
      return this.currRecites.length;
    },
    waitTopics() {
      if(this.reciteList.length > MAXLEN) {
        return this.currRecites.length * 5;
      }else {
        return this.reciteList.length * 5;
      }
    },
    RoundBtnTxt() {
      return this.hasRound ? "继续背诵" : "开始背诵";
    },
  },
  methods: {
    onLink() {
      if(this.hasFinsh) {
        this.$router.push('/book');
      }else {
        this.$router.go(-1);
      }
    },
    onToggle(val, idx) {
      if (this.count > this.resRecites.length - 1) {
        this.count = 0;
      }
      let currItem = this.resRecites[this.count];
      this.currRecites[idx] = currItem;
      this.resRecites[this.count] = val;
      this.count++;
      this.$forceUpdate();
    },
    normalizeList() {
      for (let index = 0; index < this.reciteList.length; index++) {
        let ele = this.reciteList[index];
        if (!(index >= MAXLEN)) {
          this.currRecites.push(ele);
        } else {
          this.resRecites.push(ele);
        }
      }
    },
    onSaveRecite() {
      if (!this.hasRound) {
        let req = {
          wordIds: this.currRecites.map((item) => item.wordId),
        };
        recitationPostRound(req).then((res) => {
          if (res) {
            this.$router.push("/details");
          }
        });
      } else {
        this.$router.push("/details");
      }
    },
    getRecitationWord() {
      recitationWord().then((res) => {
        this.reciteList = res.data;
        this.reciteList.forEach((item) => {
          item.states = [0, 0, 0, 0, 0];
        });
        if (!this.hasRound) {
          if (this.reciteList.length > MAXLEN) {
            this.normalizeList();
          } else {
            this.currRecites = [...this.reciteList];
          }
        }
      });
    },
    getRecitationRound() {
      recitationRound().then((res) => {
        this.hasRound = !!res.data.hasRound;
        if (this.hasRound) {
          this.currRecites = res.data.wordProgresses;
        }
        this.getRecitationWord();
      });
    },
  },
  created() {
    this.getRecitationRound();
    this.hasFinsh = getIsFinish();
  },
  components: {
    Item,
  },
  beforeRouteEnter(to, from, next) {
    if(to.path !== 'recite') {
      removeIsFinish();
    }
    next();
    console.log(to,from);
  },
};
</script>

<style lang="less" scoped>
  .recite-wrapper {
    width: 100%;
    background: #fafafa;
  }
@media (min-width: 768px) and (max-width: 2640px) {
  .recite-top {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .recite-header {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: center;
      font-size: 16px;
      position: relative;
      .recite-left {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
      }
      .goback {
        width: 22px;
        height: 22px;
        line-height: 22px;
        color: #8c8c8c;
        cursor: pointer;
        margin-right: 10px;
      }
      .recite-center {
        display: flex;
        color: #252525;
        line-height: 20px;
        p {
          margin: 0;
        }
        span {
          font-size: 28px;
        }
      }
    }
  }

  .recite-content {
    background: #fafafa;
    display: flex;
    justify-content: center;
    .result-main {
      width: 1200px;
      padding-top: 160px;
      background: #ffffff;
    }
    .recite-result {
      width: 600px;
      height: 80px;
      background-image: url("../../assets/sayhi.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      margin-bottom: 50px;
      position: relative;
      .result-text {
        position: absolute;
        margin: 0;
        right: 48px;
        top: 40px;
      }
    }
    .go-link {
      display: flex;
      justify-content: center;
      .go-home {
        border-radius: 30px;
        background: #eaf0ef;
        width: 60px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        color: #2c908c;
        margin-right: 20px;
        cursor: pointer;
      }
      .go-recite {
        border-radius: 30px;
        background: #2c908c;
        width: 120px;
        height: 36px;
        color: #ffffff;
        font-size: 14px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
      }
    }
    .recite-main {
      margin-top: 45px;
      width: 1200px;
      height: 800px;
      padding: 0 10px;
      box-sizing: border-box;

      .recite-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      .recite-bottom {
        display: flex;
        justify-content: center;
        .recite-start,
        .recite-on {
          border-radius: 71px;
          width: 180px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #ffffff;
          font-size: 16px;
          cursor: pointer;
        }
        .recite-start {
          background: #2c908c;
        }
        .recite-on {
          background: #ed7632;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .recite-top {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 14px;
    .recite-header {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      position: relative;
      .go-xl-back {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 22px;
        height: 22px;
        color: #8c8c8c;
        cursor: pointer;
      }
      .recite-left {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
      }
      .goback {
        width: 22px;
        height: 22px;
        margin-right: 10px;
      }
      .recite-center {
        display: flex;
        color: #252525;
        font-size: 14px;
        p {
          margin: 0;
        }
        span {
          font-size: 24px;
        }
      }
    }
  }
  .recite-content {
    background: #fafafa;
    display: flex;
    justify-content: center;

    .result-main {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
    }
    .recite-result {
      width: 100%;
      height: 60px;
      background-image: url("../../assets/sayhi2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 10px;
      position: relative;
      .result-text {
        position: absolute;
        right: 20px;
        top: 10px;
        p {
          margin: 0;
        }
      }
    }
    .go-link {
      display: flex;
      justify-content: center;
      .go-home {
        border-radius: 30px;
        background: #eaf0ef;
        width: 60px;
        height: 36px;
        margin-right: 20px;
        text-align: center;
        line-height: 36px;
        color: #2c908c;
      }
      .go-recite {
        border-radius: 30px;
        background: #2c908c;
        width: 120px;
        height: 36px;
        color: #ffffff;
        font-size: 14px;
        text-align: center;
        line-height: 36px;
      }
    }
    .recite-main {
      margin-top: 9px;
      width: 1200px;
      padding: 0 10px;
      box-sizing: border-box;
      .recite-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      .recite-bottom {
        display: flex;
        justify-content: center;
        .recite-start,
        .recite-on {
          border-radius: 71px;
          width: 180px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #ffffff;
          font-size: 16px;
          cursor: pointer;
        }
        .recite-start {
          background: #2c908c;
        }
        .recite-on {
          background: #ed7632;
        }
      }
    }
  }
}
</style>