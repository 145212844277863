import Vue from 'vue'
import {
    Form,
    FormItem,
    Button,
    Input,
    Message,
    Select,
    Option,
    Progress,
    Pagination,
    Empty,
    Switch,
    Dialog,
    Upload,
    Image
} from 'element-ui'

const element = {
    install: function(Vue) {
        Vue.use(Button)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(Input)
        Vue.use(Select)
        Vue.use(Option)
        Vue.use(Progress)
        Vue.use(Pagination)
        Vue.use(Empty)
        Vue.use(Switch)
        Vue.use(Dialog)
        Vue.use(Upload)
        Vue.use(Image)
    }
}
Vue.prototype.$message = Message

export default element