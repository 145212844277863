import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login'
import Home from '@/views/home'
import Word from '@/views/word'
import Book from '@/views/book'
import Recite from '@/views/recite'
import Details from '@/views/details'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect: '/home',
  },
  {
    path: '/login',
    component: Login
  },
  {
    path:'/home',
    component: Home
  },
  {
    path: '/word',
    component: Word
  },
  {
    path: '/book',
    component: Book
  },
  {
    path: '/recite',
    component: Recite
  },
  {
    path: '/details',
    component: Details
  }
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
