<template>
  <div class="login-container">
    <div class="login-center-content">
      <div class="login-item-left" v-if="needLeft"></div>
      <div class="login-item-right" v-if="showTpl === 'start'">
        <p class="item-title text">
          Repeat the words with the national students
        </p>
        <h1 class="item-message text">和全国同学一起复习单词</h1>
        <p class="item-rules text">
          词汇背诵的基本规则就是：重复<sup>n</sup>！
        </p>
        <div class="btn-line">
          <button class="btn-register" @click="showTpl = 'register'">
            注册
          </button>
          <button class="btn-login" @click="showTpl = 'login'">登录</button>
        </div>
      </div>
      <div class="register-right" v-if="showTpl === 'register'">
        <h2 class="register-title text" v-if="!isRegisterSucc">新用户注册</h2>
        <login-form
          v-if="!isRegisterSucc"
          :formModel="registerModel"
          :formRules="registerRules"
          :currTpl="showTpl"
          @go="onGo"
          @submit="onSubmit"
          ref="registerRef"
        ></login-form>
        <div class="need-xl-flx-space" v-if="isRegisterSucc">
          <h2 class="register-title text need-lg-center need-xl-center">
            新注册用户成功！
          </h2>
          <div class="go-home" @click="onLogin">立即登录</div>
        </div>
      </div>
      <div class="login-right" v-if="showTpl === 'login'">
        <div class="login-tabs">
          <span
            @click="loginWay = 'pass'"
            :class="[loginWay === 'pass' ? 'curr-font' : '']"
            >密码登录</span
          >
          <span
            @click="loginWay = 'ver'"
            :class="[loginWay === 'ver' ? 'curr-font' : '']"
            >验证码登录</span
          >
        </div>
        <div v-show="loginWay === 'pass'">
          <login-form
            :formModel="passModel"
            :formRules="passRules"
            :needVer="true"
            @go="onGo"
            @submit="onSubmit"
            @forget="onForget"
            :currTpl="showTpl"
          ></login-form>
        </div>
        <div v-show="loginWay === 'ver'">
          <login-form
            :formModel="verModel"
            :formRules="verRules"
            :needPass="true"
            @go="onGo"
            @submit="onSubmit"
            :currTpl="showTpl"
          ></login-form>
        </div>
      </div>
      <div class="register-right" v-if="showTpl === 'forget'">
        <h2 class="register-title text" v-if="!isForgetSucc">重置密码</h2>
        <login-form
          v-if="!isForgetSucc"
          :formModel="forgetModel"
          :formRules="registerRules"
          :currTpl="showTpl"
          @go="onGo"
          @submit="onReset"
          @forget="onForget"
        ></login-form>
        <div class="need-xl-flx-space" v-if="isForgetSucc">
          <h2 class="register-title text need-lg-center need-xl-center">
            重设密码成功
          </h2>
          <div class="go-home" @click="onLogin">立即登录</div>
        </div>
      </div>
    </div>
    <div class = "authorize-info">
          <a href="https://beian.miit.gov.cn">沪ICP备19024294号-6</a>
        </div>
 </div>
</template>

<script>
import LoginForm from "./login-form";
import {
  checkMobile,
  checkEmail,
  checkPassWord,
  checkCaptcha,
  setToken,
} from "@/utils";
import { register, login, existUserName, resetPassWord } from "@/api/user";

export default {
  data() {
    const validateName = (rule, value, cb) => {
      if (!value) {
        cb("手机号/邮箱不能为空!");
        return;
      }
      let mobileRes = checkMobile(value);
      let emailRes = checkEmail(value);
      mobileRes || emailRes ? cb() : cb("内容不合法!");
    };
    const validatePass = (rule, value, cb) => {
      if (!value) {
        cb("密码不能为空!");
        return;
      }
      let passRes = checkPassWord(value);
      passRes ? cb() : cb("内容不合法!");
    };
    const validateVerCode = (rule, value, cb) => {
      if (!value) {
        cb("验证码不能为空!");
        return;
      }
      let verRes = checkCaptcha(value);
      verRes ? cb() : cb("内容不合法!");
    };
    return {
      showTpl: "start",
      isRegisterSucc: false,
      hasUser: false,
      forgetModel: {
        username: "",
        password: "",
        captcha: "",
      },
      registerModel: {
        username: "",
        password: "",
        captcha: "",
      },
      registerRules: {
        username: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        captcha: [{ validator: validateVerCode, trigger: "blur" }],
      },
      passModel: {
        username: "",
        password: "",
      },
      passRules: {
        username: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
      },
      verModel: {
        username: "",
        captcha: "",
      },
      verRules: {
        username: [{ validator: validateName, trigger: "blur" }],
        captcha: [{ validator: validateVerCode, trigger: "blur" }],
      },
      needLeft: false,
      loginWay: "pass",
      isForgetSucc: false
    };
  },
  watch: {
    clientWidth: {
      handler(val) {
        if (this.showTpl !== "start" && val <= 768) {
          this.needLeft = false;
        } else {
          this.needLeft = true;
        }
      },
      immediate: true,
    },
    showTpl: {
      handler(val) {
        if (val !== "start" && this.clientWidth <= 768) {
          this.needLeft = false;
        } else {
          this.needLeft = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    onReset() {
      let req = {
        username: this.forgetModel.username,
        password: this.forgetModel.password,
        captcha: this.forgetModel.captcha,
      };
      resetPassWord(req).then((res) => {
        if (res) {
          this.isForgetSucc = true;
        }
      });
    },
    onSubmit(val) {
      if (val === "register") {
        existUserName({ username: this.registerModel.username }).then((res) => {
          this.hasUser = !!res.data.isExist;
          if (!this.hasUser) {
            register(this.registerModel).then((res) => {
              if (res) {
                this.isRegisterSucc = true;
              }
            });
          }else {
            this.$message.error("用户已存在")
          }
        });
      } else if (val === "pass" || val === "ver") {
        login(this[val + "Model"]).then((res) => {
          if (res) {
            setToken(res.data);
            this.$router.push("/home");
          }
        });
      }
    },
    onLogin() {
      if (this.isRegisterSucc) {
        let req = {
          username: this.registerModel.username,
          password: this.registerModel.password,
        };
        this.sendLogin(req);
      } else if (this.isForgetSucc) {
        let req = {
          username: this.forgetModel.username,
          password: this.forgetModel.password,
        };
        this.sendLogin(req);
      }
    },
    sendLogin(req) {
      login(req).then((res) => {
        if (res) {
          setToken(res.data);
          this.$router.push("/home");
        }
      });
    },
    onForget(val) {
      if (val) {
        this.showTpl = val;
      }
    },
    onGo(val) {
      if (!val) return;
      this.showTpl = val;
    },
  },
  components: {
    LoginForm,
  },
};
</script>

<style lang="less" scoped>
.login-container {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/banner.png");
  background-repeat: no-repeat;
  position: relative;
}
/deep/ .el-form-item.is-error .el-input__inner {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: red !important;
}
.authorize-info a{
        font-size : 10px;
        text-align:center;
        width:100%;
        bottom:5%;
        position:absolute;
}
@media (min-width: 768px) and (max-width: 2640px) {
  .login-center-content {
    background: #ffffff;
    width: 1200px;
    height: 720px;
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 80px rgba(193, 207, 204, 0.24);
    border-radius: 24px;
    .go-home {
      width: 100%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      border-radius: 30px;
      background: #2c908c;
      color: #ffffff;
      font-size: 16px;
      cursor: pointer;
      margin-top: 38px;
    }
    .login-right {
      padding-top: 20px;
      .login-tabs {
        margin-top: 20px;
        margin-bottom: 34px;
        display: flex;
        color: #595959;
        font-size: 18px;
        align-items: center;
        span {
          cursor: pointer;
        }
        span:first-child {
          margin-right: 40px;
        }
      }
      .curr-font {
        color: #252525;
        font-weight: bold;
        font-size: 24px;
      }
    }
    .register-right,
    .login-right {
      flex: 1;
      // padding-top: 156px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding-right: 130px;
      background: #ffffff;
      .register-title {
        color: #252525;
        font-size: 24px;
        margin-bottom: 34px;
      }
    }
    .login-item-left {
      width: 550px;
      margin-right: 100px;
      background-size: 550px, 660px;
      background-image: url("../../assets/zuotu.png");
      background-repeat: no-repeat;
      border-radius: 20px;
    }
    
    .login-item-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: left;
      padding-right: 130px;
      .item-title {
        color: #ed7632;
        font-size: 12px;
      }
      .item-message {
        color: #262626;
        font-size: 56px;
      }
      .item-rules {
        color: #5e5c6c;
        font-size: 16px;
        margin-bottom: 81px;
      }
      .btn-line {
        width: 100%;
        .btn-register,
        .btn-login {
          width: 160px;
          height: 60px;
          border-radius: 30px;
          margin-right: 20px;
          line-height: 60px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;
        }
        .btn-register {
          background: #ed7632;
        }
        .btn-login {
          background: #2c908c;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .login-center-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .go-home {
      width: 100%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      border-radius: 30px;
      background: #2c908c;
      color: #ffffff;
      font-size: 16px;
      cursor: pointer;
      margin-top: 38px;
    }
    .login-item-left {
      width: 100%;
      height: 370px;
      background-image: url("../../assets/zuotu@2x.png");
      background-repeat: no-repeat;
    }
    .login-right {
      .login-tabs {
        margin-top: 20px;
        margin-bottom: 28px;
        display: flex;
        color: #595959;
        font-size: 18px;
        align-items: center;
        span {
          cursor: pointer;
        }
        span:first-child {
          margin-right: 40px;
        }
      }
      .curr-font {
        color: #252525;
        font-weight: bold;
        font-size: 24px;
      }
    }
    .register-right,
    .login-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 0 20px;
      background: #ffffff;
      .register-explain {
        margin: 0;
        margin-top: 12px;
        color: #8c8c8c;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        span {
          color: #2c908c;
        }
      }
      .register-title {
        color: #252525;
        font-size: 24px;
        margin-bottom: 20px;
      }
      .register-btn {
        width: 100%;
        height: 48px;
        line-height: 48px;
        text-align: center;
        border-radius: 30px;
        background: #2c908c;
        color: #ffffff;
        font-size: 16px;
        cursor: pointer;
        margin-top: 38px;
      }
      /deep/ .el-form-item__content {
        position: relative;
        .password-msg {
          color: #8c8c8c;
          position: absolute;
          right: 0;
          bottom: -23px;
          margin: 0;
          font-size: 12px;
          line-height: 12px;
        }
        .ver-btn {
          position: absolute;
          right: 0;
          bottom: 10px;
          padding: 7px 15px;
          border-radius: 20px;
          border: 1px solid #2c908c;
          color: #2c908c;
          font-size: 14px;
          line-height: 14px;
          text-align: center;
          cursor: pointer;
        }
      }
      /deep/ .el-input__inner {
        padding: 0;
        border-radius: 0;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
      }
      /deep/ .el-input__inner:focus {
        border-bottom-color: #2c908c;
      }
      /deep/ .el-input-group__append {
        position: relative;
        background: transparent;
        border-right: transparent;
        border-top: transparent;
      }
    }
    .login-item-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: left;
      padding: 0 20px;
      .item-title {
        color: #ed7632;
        font-size: 12px;
      }
      .item-message {
        color: #262626;
        font-size: 50px;
      }
      .item-rules {
        color: #5e5c6c;
        font-size: 14px;
        margin-bottom: 30px;
      }
      .btn-line {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .btn-register,
        .btn-login {
          width: 140px;
          height: 44px;
          border-radius: 30px;
          //   margin-right: 10px;
          line-height: 44px;
          text-align: center;
          font-size: 18px;
          color: #ffffff;
          cursor: pointer;
        }
        .btn-register {
          background: #ed7632;
        }
        .btn-login {
          background: #2c908c;
        }
      }
    }
  }
}
</style>