export function getCurrIndex(key) {
    switch (key) {
      case 49:
        return 0;
      case 50:
        return 1;
      case 51:
        return 2;
      case 52:
        return 3;
      default:
        return 0;
    }
}

export function createAudio(arr) {
    let audio = document.createElement("audio");
    audio.src = arr.shift();
    audio.controls = "";
    audio.hidden = true;
    audio.addEventListener("ended", function () {
      console.log(arr);
      if (arr.length === 0) {
        document.body.removeChild(audio);
        return;
      }
      audio.src = arr.shift();
      audio.play();
    });
    audio.play();
    document.body.appendChild(audio);
}