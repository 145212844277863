<template>
  <div class="home-wrapper">
    <div class="home-top">
      <div class="home-header">
        <div class="home-phase">
          <el-select
            v-model="userModel.currLearnStage"
            @change="onChangeLearn"
            placeholder="请选择"
            popper-class="green"
            :disabled="canLearnStage"
          >
            <el-option
              v-for="item in learningStage"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="home-user">
          <div class="user-photo" v-if="userInfo">
            <img :src="userInfo.avatar" alt="" />
          </div>
          <div class="user-name" @click="onOpenSet" v-if="userInfo">
            {{ userInfo.nickname }}
            <i
              class="icon-a-Property1xiala iconfont icon need-xl-hidden"
              :class="showInfo ? 'zhuan1' : 'zhuan2'"
            ></i>
          </div>
          <div
            class="user-setting"
            v-show="showInfo"
            @mouseleave="showInfo = false"
          >
            <div class="setting-item" @click="onSetting">
              <i class="iconfont icon-a-shezhi_setting-two11"></i>
              <span>账户设置</span>
            </div>
            <div class="setting-item" @click="onLoginOut">
              <i class="iconfont icon-a-tuichu_logout11"></i>
              <span>退出登录</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-content">
      <div class="home-banner">
        <div class="home-nickname">
          <div class="banner-name">
            <span>你好呀，</span>
            <span v-if="userInfo" class="name">{{ userInfo.nickname }}</span>
          </div>
          <span>重复背词，越背越会，突破难题，共赴金榜</span>
        </div>
      </div>
      <div class="home-main">
        <div class="main-left">
          <div class="main-recite">
            <div class="recite-title">
              <h3 class="title-h-text">背诵生词</h3>
              <p class="title-p-text">
                {{ roundTimeTxt }}
              </p>
            </div>
            <div class="recite-set">
              <button
                class="recite-btn"
                :class="roundStatus === 'empty' ? 'disabled' : ''"
                @click="onLink"
              >
                {{ roundBtnTxt }}
              </button>
            </div>
          </div>
          <div class="main-data">
            <div v-if="wordList.length" class="data-list">
              <Item
                v-for="item in wordList"
                :content="item"
                :key="item.wordId"
                style="width: 100%"
              ></Item>
            </div>
            <div class="no-data" v-else>
              <div class="no-data-img"></div>
              <p class="no-data-text">
                {{noDataTxt}}
                
                </p>
            </div>
          </div>
        </div>
        <div class="main-right">
          <div class="main-search">
            <div class="search-title">
              <h3 class="title-h-text">查询生词</h3>
              <p class="title-p-text">
                查询各条件下的词汇，可以加入生词本，之后进行背诵
              </p>
            </div>
            <div class="search-list">
              <div class="search-item">
                <h2 class="title-h-text search-num">{{ wordNum }}</h2>
                <p class="title-p-text">本网站累计录入词汇</p>
              </div>
              <button class="search-btn" @click="$router.push('/word')">
                立即查询
                <i class="icon iconfont icon-jiantou"></i>
              </button>
            </div>
          </div>
          <div class="main-myword">
            <div class="myword-title">
              <h3 class="title-h-text pr">
                我的词汇本
                <span class="row" @click="$router.push('/book')">
                  <i class="iconfont icon-a-Property1jinru-changtai"></i>
                </span>
              </h3>
            </div>
            <div class="mybook-list">
              <div class="search-item">
                <h2 class="title-h-text search-num">{{ myWordNum }}</h2>
                <p class="title-p-text">我的词汇总数</p>
              </div>
              <div class="search-item">
                <h2 class="title-h-text search-num">{{ reciteNum }}</h2>
                <p class="title-p-text">当前生词数量</p>
              </div>
              <div class="search-item">
                <h2 class="title-h-text search-num">{{ familiarNum }}</h2>
                <p class="title-p-text">当前熟词数量</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DiaLog :visible="firstVisible">
      <div class="first-dialog">
        <div class="first-user-name">
          <span>你好呀，</span>
          <span>{{ userModel.titleName }}</span>
        </div>
        <div class="first-user-message">
          <p>欢迎来重复背词，相信你会更强大！</p>
          <p>首先，</p>
          <p>你还需要完善一些信息，以便在没有积累生词时自动为你选择词汇。</p>
        </div>
        <el-form
          :model="userModel"
          label-position="top"
          :rules="userLearnRules"
          ref="learnForm"
        >
          <el-form-item label="阶段" prop="currLearnStage">
            <el-select
              v-model="userModel.currLearnStage"
              popper-class="green"
              placeholder="幼儿园"
            >
              <el-option
                v-for="item in learningStage"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <p class="first-user-passmsg p-left" v-show="currLearnStageFlag">
              真选幼儿园么！不存在的，，幼儿园不需要背单词！！
            </p>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input
              class="first-input"
              v-model="userModel.name"
              placeholder="整个名字"
            ></el-input>
            <p class="first-user-passmsg tr p-right" v-show="nameFlag">
              姓名为1~16位数字、字母、符号。
            </p>
          </el-form-item>
          <el-form-item class="tc">
            <button @click.prevent="onSubmit" class="first-user-btn">
              开始探索
            </button>
          </el-form-item>
        </el-form>
      </div>
    </DiaLog>
    <DiaLog :visible="changeVisible">
      <div class="change-dialog">
        <div class="change-title">修改用户信息</div>
        <el-form
          :model="editUserModel"
          :rules="editUserRules"
          label-position="top"
          ref="editForm"
        >
          <el-form-item label="姓名" prop="name">
            <el-input
              class="first-input"
              v-model="editUserModel.name"
              placeholder="整个名字"
            ></el-input>
            <p class="first-user-passmsg tr">姓名为1~16位数字、字母、符号。</p>
          </el-form-item>
          <el-form-item :label="oldPassTxt" prop="password">
            <el-input
              class="first-input pr"
              v-model="editUserModel.password"
              type="password"
            ></el-input>
            <span class="second-password row" @click="onChangePass">{{
              changeTxt
            }}</span>
            <p v-show="showNew" class="first-user-passmsg tr">
              密码为6~16位数字、字母或符号
            </p>
          </el-form-item>
          <el-form-item v-show="showNew" label="输入新密码" prop="newPassword">
            <el-input
              class="first-input pr"
              v-model="editUserModel.newPassword"
              type="password"
              show-password
            ></el-input>
          </el-form-item>
          <div v-show="!showNew" class="is-hidden"></div>
          <el-form-item>
            <div class="flx">
              <button
                @click.prevent="onPassSubmit"
                :disabled="!canEdit"
                :class="!canEdit ? 'dis' : ''"
                class="first-user-btn w1"
              >
                确认保存
              </button>
              <button
                @click.prevent="changeVisible = false"
                class="first-user-btn w2"
              >
                取消
              </button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </DiaLog>
  </div>
</template>

<script>
import Item from "@/components/item";
import DiaLog from "@/components/dialog";
import { userInfo, editUserInfo, userStat, logout } from "@/api/user";
import { recitationRound, recitationWord } from "@/api/recitation";
import { removeToken, checkPassWord, MAXLEN } from "@/utils";
export default {
  data() {
    const validatePass = (rule, value, cb) => {
      if (!value) {
        cb("密码不能为空!");
        return;
      }
      let passRes = checkPassWord(value);
      passRes ? cb() : cb("内容不合法!");
    };
    const validateNewPass = (rule, value, cb) => {
      if (!value) {
        cb("新密码不能为空!");
        return;
      }
      let passRes = checkPassWord(value);
      passRes ? cb() : cb("内容不合法!");
    };
    return {
      phase: "",
      phases: [
        {
          label: "幼儿园",
          value: 1,
        },
      ],
      learningStage: [
        {
          label: "初中",
          value: 1,
        },
        {
          label: "高中",
          value: 2,
        },
        {
          label: "大学四级",
          value: 3,
        },
        {
          label: "大学六级",
          value: 4,
        },
        {
          label: "考研",
          value: 5,
        },
        {
          label: "托福",
          value: 6,
        },
        {
          label: "雅思",
          value: 7,
        },
      ],
      firstVisible: false,
      changeVisible: false,
      userInfo: null,
      userModel: {
        currLearnStage: "",
        name: "",
        titleName: "",
      },
      currLearnStageFlag: true,
      nameFlag: true,
      userLearnRules: {
        currLearnStage: [
          { required: true, message: "别跑，选一个!", trigger: "change" },
        ],
        name: [
          {
            required: true,
            min: 1,
            max: 16,
            message: "长度在 1 到 16 个字符",
            trigger: "blur",
          },
        ],
      },
      editUserRules: {
        name: [
          {
            required: true,
            min: 1,
            max: 16,
            message: "长度在 1 到 16 个字符",
            trigger: "blur",
          },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        newPassword: [{ validator: validateNewPass, trigger: "blur" }],
      },
      editUserModel: {
        name: "",
        password: "..........",
        newPassword: "",
      },
      oldUserModel: null,
      roundStatus: "empty",
      wordList: [],
      showInfo: false,
      showNew: false,
      hasRound: false,
      time: "",
      duration: "",
      canEdit: false,
      wordNum: 0,
      myWordNum: 0,
      familiarNum: 0,
      reciteNum: 0,
    };
  },
  computed: {
    noDataTxt() {
      return !!this.reciteNum ? "太棒了，生词本中的词汇都掌握了！ " : "你还没有选择任何生词，查询添加生词吧！";
    },
    canLearnStage() {
      return this.firstVisible;
    },
    roundBtnTxt() {
      return this.roundStatus === "empty" || this.roundStatus === "zero"
        ? "开始背诵"
        : "继续背诵";
    },
    roundTimeTxt() {
      return this.roundStatus === "zero"
        ? "以下词汇均是你的生词本中最需要背诵的词汇"
        : this.roundStatus === "hasRound"
        ? `从 ${this.time} 开始，累计背诵了${this.duration}`
        : "";
    },
    changeTxt() {
      return this.showNew ? "返回" : "更改";
    },
    oldPassTxt() {
      return this.showNew ? "输入旧密码" : "密码";
    },
  },
  watch: {
    "userModel.currLearnStage": {
      handler() {
        this.$nextTick(() => {
          this.$refs.learnForm &&
            this.$refs.learnForm.validateField("currLearnStage", (res) => {
              this.currLearnStageFlag = res ? false : true;
            });
        });
      },
    },
    "userModel.name": {
      handler() {
        this.$nextTick(() => {
          this.$refs.learnForm &&
            this.$refs.learnForm.validateField("name", (res) => {
              this.nameFlag = res ? false : true;
            });
        });
      },
    },
    changeVisible(val) {
      this.showInfo = false;
      if (!val) {
        this.showNew = false;
        Object.keys(this.editUserModel).forEach((item) => {
          this.editUserModel[item] = "";
          if (item === "password") {
            this.editUserModel[item] = "..........";
          }
        });
      }
    },
    editUserModel: {
      deep: true,
      handler(val) {
        if (
          val.name !== this.oldUserModel.name ||
          (val.password !== this.oldUserModel.password && this.showNew)
        ) {
          this.canEdit = true;
        } else {
          this.canEdit = false;
        }
      },
    },
  },
  methods: {
    onChangeLearn(val) {
      let req = {
        learningStage: val,
      };
      editUserInfo(req).then((res) => {
        if (res) {
          this.$message.success("修改成功!");
          this.getUserInfo();
        }
      });
    },
    onLoginOut() {
      logout().then((res) => {
        removeToken();
        this.$router.push("/login");
      });
    },
    onPassSubmit() {
      let nameFlag, newPassFlag, passFlag;
      this.$refs.editForm.validateField("name", (res) => {
        nameFlag = res ? false : true;
      });

      if (this.showNew) {
        this.$refs.editForm.validateField("newPassword", (res) => {
          newPassFlag = res ? false : true;
        });
        this.$refs.editForm.validateField("password", (res) => {
          passFlag = res ? false : true;
        });
      }
      if (this.showNew) {
        if (nameFlag && newPassFlag && passFlag) {
          let req = {
            nickname: this.editUserModel.name,
            newPassword: this.editUserModel.newPassword,
            oldPassword: this.editUserModel.password,
          };
          editUserInfo(req).then((res) => {
            if (res) {
              this.$message.success("修改成功!");
              this.getUserInfo();
              this.changeVisible = !this.changeVisible;
              this.showNew = !this.showNew;
            }
          });
        }
      } else {
        if (nameFlag) {
          let req = {
            nickname: this.editUserModel.name,
          };
          editUserInfo(req).then((res) => {
            if (res) {
              this.$message.success("修改成功!");
              this.getUserInfo();
              this.changeVisible = !this.changeVisible;
              this.showNew = !this.showNew;
            }
          });
        }
      }
    },
    formDuration(mss) {
      var minutes = parseInt((mss % (1000 * 60 * 1000)) / (1000 * 60));
      var seconds = parseInt((mss % (1000 * 60)) / 1000);
      return `${minutes}分${seconds}秒`;
    },
    onLink() {
      if (this.roundStatus === "zero" || this.roundStatus === "hasRound") {
        this.$router.push("/recite");
      }
    },
    onSetting() {
      this.changeVisible = true;
      this.editUserModel.name = this.userInfo.nickname;
    },
    onChangePass() {
      this.showNew = !this.showNew;
    },
    onOpenSet() {
      this.showInfo = !this.showInfo;
    },
    onSubmit() {
      this.$refs.learnForm.validateField("currLearnStage", (res) => {
        this.currLearnStageFlag = res ? false : true;
      });
      this.$refs.learnForm.validateField("name", (res) => {
        this.nameFlag = res ? false : true;
      });
      if (this.currLearnStageFlag && this.nameFlag) {
        let req = {
          learningStage: this.userModel.currLearnStage,
          nickname: this.userModel.name,
        };
        editUserInfo(req).then((res) => {
          if (res) {
            this.$message.success("修改成功!");
            this.getUserInfo();
            this.firstVisible = !this.firstVisible;
          }
        });
      }
    },
    getUserInfo() {
      userInfo().then((res) => {
        this.userModel.name = res.data.nickname;
        this.userModel.titleName = res.data.nickname;
        this.userModel.currLearnStage =
          res.data.learningStage === 0 ? "" : res.data.learningStage;
        this.userInfo = res.data;
        if (!this.userModel.currLearnStage) {
          this.firstVisible = true;
        }
        this.oldUserModel = {
          name: this.userInfo.nickname,
          password: "..........",
          newPassword: "",
        };
        Object.freeze(this.oldUserModel);
      });
    },
    getRecitationRound() {
      recitationRound().then((res) => {
        this.hasRound = !!res.data.hasRound;
        if (this.hasRound) {
          this.roundStatus = "hasRound";
          this.wordList = res.data.wordProgresses;
          this.time = res.data.beginTime;
          this.duration = this.formDuration(res.data.duration);
        } else {
          this.getRecitationWord();
        }
      });
    },
    getRecitationWord() {
      recitationWord().then((res) => {
        if (res.data && res.data.length !== 0) {
          this.roundStatus = res.data.length !== 0 ? "zero" : "empty";
          let arr = [...res.data];
          arr.forEach((item) => {
            item.states = [0, 0, 0, 0, 0];
          });
          if (arr.length > MAXLEN) {
            arr = arr.splice(0, 5);
          }
          this.wordList = arr;
        }
      });
    },
    getUserStat() {
      userStat().then((res) => {
        this.wordNum = res.data.wordNum;
        this.myWordNum = res.data.myWordNum;
        this.familiarNum = res.data.familiarNum;
        this.reciteNum = res.data.reciteNum;
      });
    },
  },
  created() {
    this.getUserInfo();
    this.getRecitationRound();
    this.getUserStat();
  },
  components: {
    Item,
    DiaLog,
  },
};
</script>

<style lang="less" scoped>
.home-wrapper {
  width: 100%;
  background: #fafafa;
}
.dis {
  background: #eaf0ef !important;
}
.row {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.tr {
  text-align: right !important;
}
.zhuan1 {
  transition: 0.2s;
  transform-origin: center;
  transform: rotateZ(180deg);
}
.zhuan2 {
  transition: 0.2s;
  transform-origin: center;
  transform: rotateZ(0);
}
.icon {
  display: block;
  transition: all 0.3s;
}
.pr {
  position: relative;
}
.p-right {
  position: absolute;
  top: 100%;
  right: 0;
}
.p-left {
  position: absolute;
  top: 100%;
  left: 0;
}

/deep/ .el-form-item {
  margin: 0;
}

.disabled {
  background: #ffd5b0 !important;
  cursor: not-allowed;
}

.first-input {
  /deep/ .el-input__inner {
    padding: 0;
    border-radius: 0;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    background: #ffffff;
  }
  /deep/ .el-input__inner:focus {
    border-bottom-color: #2c908c;
  }
}
.second-password {
  color: #2c908c;
}
@media (min-width: 768px) and (max-width: 2640px) {
  /deep/ .el-form-item__content {
    margin-bottom: 28px;
  }
  /deep/ .el-select {
    width: 160px;
    height: 36px;
  }
  /deep/ .el-input__inner {
    border-radius: 4px;
    background: #f7f6f4;
    border: 1px solid #f0f0f0;
  }
  .first-user-passmsg {
    color: #8c8c8c;
    line-height: 1;
    font-size: 12px;
    margin: 8px 0;
  }
  .w1 {
    width: 244px !important;
    margin-right: 16px;
    margin-top: 53px !important;
  }
  .w2 {
    width: 100px !important;
    color: #2c908c !important;
    background: #ffffff !important;
    border: 1px solid #2c908c;
  }
  .first-user-btn {
    width: 360px;
    height: 40px;
    border-radius: 30px;
    background: #2c908c;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    margin-top: 9px;
  }
  .home-top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background: #ffffff;
    .home-header {
      width: 1200px;
      display: flex;
      justify-content: space-between;
    }
    .home-user {
      display: flex;
      align-items: center;
      position: relative;
      .user-photo {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 7px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        display: flex;
        color: #595959;
        font-size: 14px;
        cursor: pointer;
      }
      .user-setting {
        position: absolute;
        top: 48px;
        right: -32px;
        width: 120px;
        padding: 8px 0;
        border-radius: 4px 0px 0px 4px;
        background: #ffffff;
        z-index: 999;
        border: 1px solid #e4e7ed;
        .setting-item {
          text-align: center;
          height: 32px;
          line-height: 32px;
          cursor: pointer;
        }
      }
    }
  }
  .home-content {
    background: #fafafa;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .home-banner {
      width: 1200px;
      height: 180px;
      margin-bottom: 20px;
      border-radius: 20px;
      position: relative;
      background-image: url(../../assets/home.png);
      background-repeat: no-repeat;
      background-size: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 200px;
      box-sizing: border-box;
      .home-nickname {
        .banner-name {
          margin-bottom: 10px;
          .name {
            font-size: 32px;
            color: #2c908c;
            font-weight: 700;
          }
        }
      }
    }
    .home-main {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      background: #fafafa;
      .title-h-text {
        color: #252525;
        font-size: 20px;
        margin-bottom: 6px;
        margin-top: 0;
      }
      .title-p-text {
        color: #8c8c8c;
        font-size: 14px;
      }
      .search-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background: #f7f6f4;
        padding: 24px 0;
        margin: 15px 0 20px 0;
      }
      .search-num {
        font-size: 36px;
        color: #2c908c;
      }
      .main-left {
        margin-right: 20px;
        border-radius: 20px;
        width: 740px;
        background: #ffffff;
        padding: 15px 20px 20px 20px;
        .main-recite {
          display: flex;
          justify-content: space-between;
          .recite-btn {
            width: 140px;
            height: 40px;
            background: #ed7632;
            color: #ffffff;
            font-size: 16px;
            line-height: 40px;
            text-align: center;
            border-radius: 71px;
            cursor: pointer;
          }
        }
        .main-data {
          .data-list {
            margin-top: 22px;
          }
          .no-data {
            margin-top: 200px;
            color: #252525;
            font-size: 16px;
            text-align: center;
            .no-data-img {
              width: 200px;
              height: 200px;
              margin: 0 auto;
              background-image: url("../../assets/round-nodata.jpg");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
            .no-data-text {
              margin-top: 24px;
            }
          }
        }
      }
      .main-right {
        width: 420px;
        background: #fafafa;
        .main-search {
          border-radius: 20px;
          margin-bottom: 20px;
          background: #ffffff;
          padding: 15px 20px 20px 20px;
          .search-btn {
            display: block;
            border-radius: 71px;
            background: #2c908c;
            width: 180px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #ffffff;
            font-size: 16px;
            margin: 0 auto;
            cursor: pointer;
          }
        }
        .main-myword {
          padding: 15px 20px 20px 20px;
          border-radius: 20px;
          background: #ffffff;
          .myword-title {
            margin-bottom: 17px;
          }
        }
      }
    }
  }
  .first-dialog {
    height: 100%;
    padding: 52px 40px 60px 40px;
    box-sizing: border-box;
    font-size: 16px;

    .first-user-name {
      margin-bottom: 12px;
      span:last-child {
        color: #2c908c;
        font-size: 24px;
        font-weight: bold;
        margin-left: 9px;
      }
    }
    .first-user-message {
      margin-bottom: 20px;
    }
  }
  .change-dialog {
    height: 560px;
    padding: 52px 40px 60px 40px;
    box-sizing: border-box;
    /deep/ .el-form-item__content {
      margin-bottom: 20px !important;
      &:last-child {
        margin: 0;
      }
    }
    /deep/ .el-form-item__label {
      line-height: 14px;
    }
    .change-title {
      color: #252525;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 35px;
    }
  }
}
@media (max-width: 768px) {
  /deep/ .el-form-item__content {
    margin-bottom: 28px;
  }
  .is-hidden {
    height: 118px;
  }
  .tc {
    text-align: center;
  }
  .flx {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  .w1 {
    width: 205px !important;
  }
  .w2 {
    width: 100px !important;
    color: #2c908c !important;
    background: #ffffff !important;
    border: 1px solid #2c908c;
  }
  /deep/ .el-select {
    width: 105px;
    /deep/ .el-input__inner {
      border-color: transparent;
    }
  }
  /deep/ .el-form-item__label {
    line-height: 1;
  }
  .first-user-btn {
    width: 190px;
    height: 44px;
    border-radius: 30px;
    background: #2c908c;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
    margin-top: 9px;
  }
  .first-user-passmsg {
    color: #8c8c8c;
    margin: 12px 0;
    line-height: 1;
    font-size: 12px;
    text-align: left;
  }
  .home-top {
    padding: 10px 12px 8px 12px;
    background: #ffffff;
    .home-header {
      display: flex;
      justify-content: space-between;
    }
    .home-user {
      display: flex;
      align-items: center;
      position: relative;
      .user-photo {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 7px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user-name {
        display: flex;
        color: #595959;
        font-size: 14px;
      }
      .user-name {
        color: #595959;
        font-size: 14px;
        cursor: pointer;
      }
      .user-setting {
        color: #595959;
        position: absolute;
        top: 48px;
        right: -4px;
        width: 120px;
        z-index: 999;
        padding: 8px 0;
        border-radius: 4px;
        background: #ffffff;
        border: 1px solid #e4e7ed;
        .setting-item {
          text-align: center;
          height: 32px;
          line-height: 32px;
          cursor: pointer;
        }
      }
    }
  }
  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 10px 10px 10px;
    box-sizing: border-box;
    .home-banner {
      width: 100%;
      height: 75px;
      margin-bottom: 20px;
      border-radius: 10px;
      position: relative;
      background-image: url(../../assets/home2.png);
      background-repeat: no-repeat;
      background-size: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 20px;
      box-sizing: border-box;
      .home-nickname {
        font-size: 12px;
        margin-top: 10px;
        width: 60%;
        .banner-name {
          margin-bottom: 10px;
          .name {
            font-size: 18px;
            color: #2c908c;
            font-weight: 700;
          }
        }
      }
    }
    .home-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      .search-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        background: #f7f6f4;
        padding: 12px 0;
        margin: 15px 0 20px 0;
      }

      .title-h-text {
        color: #252525;
        font-size: 16px;
        margin-bottom: 6px;
        margin-top: 0;
      }
      .title-p-text {
        color: #8c8c8c;
        font-size: 12px;
      }
      .search-num {
        font-size: 36px;
        color: #2c908c;
      }
      .main-left {
        padding: 12px 10px 10px 10px;
        box-sizing: border-box;
        border-radius: 10px;
        width: 100%;
        background: #ffffff;
        .main-recite {
          display: flex;
          flex-direction: column;
          .recite-set {
            display: flex;
            justify-content: flex-end;
            .recite-btn {
              width: 90px;
              height: 36px;
              background: #ed7632;
              color: #ffffff;
              font-size: 14px;
              line-height: 36px;
              text-align: center;
              border-radius: 35px;
            }
          }
        }
        .main-data {
          .data-list {
            margin-top: 22px;
          }
          .no-data {
            color: #252525;
            font-size: 14px;
            text-align: center;
            .no-data-img {
              width: 100px;
              height: 100px;
              margin: 0 auto;
              background-image: url("../../assets/round-nodata.jpg");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
            .no-data-text {
              margin-top: 10px;
            }
          }
        }
      }
      .main-right {
        width: 100%;
        background: #fafafa;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        border-radius: 10px;
        .main-search {
          border-radius: 10px;
          margin-bottom: 10px;
          background: #ffffff;
          padding: 12px 10px 10px 10px;
          .search-btn {
            display: block;
            border-radius: 71px;
            background: #2c908c;
            width: 180px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: #ffffff;
            font-size: 16px;
            margin: 0 auto;
            cursor: pointer;
          }
        }
        .main-myword {
          padding: 12px 10px 10px 10px;
          box-sizing: border-box;
          border-radius: 10px;
          background: #ffffff;
        }
      }
    }
  }
  .first-dialog {
    height: 100%;
    padding: 15px 10px 20px 10px;
    box-sizing: border-box;
    font-size: 12px;

    /deep/ .el-form-item__label {
      line-height: 1;
      padding-bottom: 4px;
      padding-top: 17px;
    }
    /deep/ .el-select .el-input__inner:focus {
      border-color: #d06456;
    }
    /deep/ .el-select {
      width: 160px;
      /deep/ .el-input__inner {
        padding-left: 4px;
      }
    }

    .first-user-name {
      margin-bottom: 12px;
      span:last-child {
        color: #2c908c;
        font-size: 18px;
        font-weight: bold;
        margin-left: 9px;
      }
    }
    .first-user-message {
      margin-bottom: 20px;
      p {
        margin: 0;
      }
    }
  }
  .change-dialog {
    padding: 20px 10px 20px 10px;
    box-sizing: border-box;
    .change-title {
      color: #252525;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 25px;
    }
  }
}
</style>